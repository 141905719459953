<template>
  Home Page
</template>

<script>

export default {
  name: 'HomePage'
}
</script>

