<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">Strategies</h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <LightButton text="New strategy" @click="createStrategy"/>
    </div>
  </div>
  <div class="mx-auto w-full">
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    <table role="table" class="transaction-table w-full border-separate border-0">
      <thead class="text-sm text-gray-500 dark:text-gray-300">
      <tr>
        <th class="w-full group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full text-left">Name</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">&nbsp;</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">&nbsp;</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">&nbsp;</div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
      <tr v-if="strategies.length === 0"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td colspan="4"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5 text-gray-400">
          Create new strategy!
        </td>
      </tr>
      <tr :key="strategy.id" v-for="strategy in strategies"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">{{ strategy.name }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Backtest" @click="createBacktest(strategy)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Edit" @click="editStrategy(strategy)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Delete" @click="deleteStrategy(strategy)"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {ref} from 'vue'
import apiService from '@/services/api.service'
import {useRouter} from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import LightButton from '@/components/LightButton'

export default {
  name: 'StrategiesPage',
  components: {
    LightButton
  },
  setup() {
    const router = useRouter()
    const strategies = ref([])
    const emitter = useEmitter()

    const createStrategy = () => {
      router.push(`/strategies/create`)
    }

    const editStrategy = (strategy) => {
      router.push(`/strategies/${strategy.id}`)
    }

    const createBacktest = (strategy) => {
      router.push(`/backtests/create?strategy=${strategy.id}`)
    }

    const deleteStrategy = (strategy) => {
      if (confirm('Delete strategy?')) {
        apiService.deleteStrategy(strategy.id)
            .then(() => {
              strategies.value = strategies.value.filter(s => s.id !== strategy.id)
              alert(`Strategy deleted`)
            })
            .catch(alert)
      }
    }

    const loadStrategies = () => {
      apiService.getStrategies()
          .then(response => {
            strategies.value = response.data
          })
    }
    loadStrategies()

    emitter.on('signIn', loadStrategies)
    emitter.on('signUp', loadStrategies)
    emitter.on('signOut', loadStrategies)

    return {
      strategies,
      createStrategy,
      editStrategy,
      deleteStrategy,
      createBacktest
    }
  }
}
</script>

