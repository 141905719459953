<template>
  <div class="relative flex h-24 items-center justify-between overflow-hidden px-6 py-4 2xl:px-8">
    <router-link :to="'/'" class="flex outline-none">
      <span class="relative flex overflow-hidden">
        <span
            style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
          <span
              style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;">
            <img src="/assets/logo.png"
                 style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;">
          </span>
        </span>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
