<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">New strategy <span
        class="font-bold text-gray-600">{{ strategyName }}</span></h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  </div>
  <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-10 mb-10" role="alert">
    <p class="font-bold mb-2">Error:</p>
    <p>{{ errorMessage }}</p>
  </div>
  <form class="mx-auto w-full">
    <div className="w-full svelte-jsoneditor-vue" ref="editor"></div>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    <div class="flex flex-wrap -mx-3 mb-6 mt-10">
      <div class="w-full md:w-1/2 px-3">
        <LightButton text="Save" @click="createStrategy"/>
      </div>
    </div>
  </form>
</template>

<script>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import apiService from '@/services/api.service'
import {JSONEditor} from 'vanilla-jsoneditor'
import LightButton from "@/components/LightButton";

export default {
  name: 'CreateStrategyPage',
  components: {LightButton},
  setup() {
    const router = useRouter()
    const loading = ref(false)
    const errorMessage = ref(null)
    const strategyName = ref('Strategy name')
    const editor = ref(null)
    const editorObject = ref(null)
    const content = ref({
      text: undefined,
      json: {
        "name": "Strategy name",
        "timeframe": "15m",
        "maxTrades": 10,
        "maxCandles": 100,
        "long": {
          "isEntry": "",
          "entry": "",
          "takeProfit": "",
          "stopLoss": ""
        },
        "short": {
          "isEntry": "",
          "entry": "",
          "takeProfit": "",
          "stopLoss": ""
        },
        "params": {},
        "backtest": {
          "indicators": {}
        }
      }
    })

    const resizeEditor = () => {
      editor.value.style.width = `${editor.value.parentNode.parentNode.offsetWidth - 20}px`
      editor.value.style.height = '600px'
    }

    const createStrategy = () => {
      loading.value = true
      errorMessage.value = null

      apiService.createStrategy(JSON.parse(editorObject.value.get().text))
          .then(response => {
            router.push(`/strategies`)
          })
          .catch(error => {
            loading.value = false
            errorMessage.value = error
          })
    }

    setTimeout(() => {
      resizeEditor()

      editorObject.value = new JSONEditor({
        target: editor.value,
        props: {
          content: content.value,
          navigationBar: false,
          mainMenuBar: false,
          mode: 'text',
          onChange: (e) => {
            strategyName.value = JSON.parse(editorObject.value.get().text).name
          }
        }
      })
    }, 100)

    return {
      createStrategy,
      loading,
      errorMessage,
      strategyName,
      content,
      editor
    }
  }
}
</script>

