<template>
  <div ref="chartParent" class="flex flex-wrap mt-10">
    <div class="flex w-full" v-if="backtest && backtest.summaries">
      <trading-vue
          ref="chart"
          :titleTxt="chartTitle"
          :data="data"
          :toolbar="false"
          :width="width"
          :overlays="overlays"
          :height="height"></trading-vue>
    </div>
  </div>
</template>

<script>
import TradingVue from '@/components/trading-vue/TradingVue'
import {computed, ref, watchEffect} from 'vue'
import {DataCube} from '@/components/trading-vue'
import {getTimeframeDiff} from "@/utils";
import apiService from "@/services/api.service";

export default {
  name: 'BacktestChart',
  components: {
    TradingVue
  },
  props: {
    backtest: {
      type: Object,
    },
    trade: {
      type: Object
    },
    market: {
      type: Object
    }
  },
  setup(props) {

    const chartParent = ref(null)
    const chart = ref(null)
    const width = ref(1000)
    const height = ref(600)
    const data = ref(new DataCube())
    const candles = ref({})

    watchEffect(() => {
      // load indicators
      // if (strategy.value.backtest && strategy.value.backtest.indicators) {
      //   for (const name of Object.getOwnPropertyNames(strategy.value.backtest.indicators)) {
      //
      //     const indicatorParams = {}
      //     for (const paramName of Object.getOwnPropertyNames(strategy.value.backtest.indicators[name].params)) {
      //       let paramValue = strategy.value.backtest.indicators[name].params[paramName]
      //       if (paramValue.startsWith('$')) {
      //         indicatorParams[`${name}_${paramName}`] = strategy.value.params[paramValue.substring(1)]
      //       } else {
      //         indicatorParams[`${name}_${paramName}`] = strategy.value.backtest.indicators[name].params[paramName]
      //       }
      //     }
      //     const indicatorParamsString = JSON.stringify(indicatorParams)
      //     indicatorParams.exchange = backtest.value.exchange
      //     indicatorParams.type = backtest.value.type
      //     indicatorParams.base = backtest.value.base
      //     indicatorParams.quote = backtest.value.quote
      //     indicatorParams.timeframe = backtest.value.timeframe
      //     indicatorParams.started_at = backtest.value.startedAt
      //     indicatorParams.finished_at = backtest.value.finishedAt
      //     indicatorParams.from = 0
      //     indicatorParams.size = 10000
      //   }
      // }
      // await apiService.loadData(
      //     name,
      //     indicatorParams
      // ).then(async response => {
      //
      //   data.value.add('onchart', {
      //     name: `${strategy.value.backtest.indicators[name].id} ${indicatorParamsString}`,
      //     type: strategy.value.backtest.indicators[name].id,
      //     data: response.data,
      //     settings: strategy.value.backtest.indicators[name].settings
      //   })
      // })
    })

    let lastTrade = null
    watchEffect(() => {
      if (props.trade && props.trade.id && (!lastTrade || lastTrade.id !== props.trade.id)) {
        const trade = lastTrade = props.trade


        console.log('current trade: ', trade)

        data.value.del('onchart.trade-entry')
        data.value.del('onchart.trade-takeProfit')
        data.value.del('onchart.trade-stopLoss')

        chart.value.setRange(trade.entryTime, trade.exitTime)

        if (trade.closed) {
          for (const entry of trade.entries) {

            // time: entry.createdAt,
            const newCandle = candles.value[trade.entryTime]

            data.value.add('onchart', {
              id: 'trade-entry',
              name: `trade-entry-new-${entry.id}`,
              type: 'LabelBox',
              data: [],
              settings: {
                time: newCandle[0],
                price: newCandle[2] * 1.01,
                text: [(entry.side === 'long' ? 'buy' : 'sell') + ':new', `p=${entry.price}`, `q=${entry.quantity}`],
                textColor: 'rgba(255, 255, 255, 1)',
                bodyColor: 'rgba(253, 224, 71, 0.8)'
              }
            })

            if (entry.status === 'filled') {
              // time: entry.filledAt,
              const filledCandle = candles.value[trade.entryTime + 1800000]

              data.value.add('onchart', {
                id: 'trade-entry',
                name: `trade-entry-filled-${entry.id}`,
                type: 'LabelBox',
                data: [],
                settings: {
                  time: filledCandle[0],
                  price: filledCandle[2] * 1.01,
                  text: [(entry.side === 'long' ? 'buy' : 'sell') + ':fill', `p=${entry.averagePrice}`, `q=${entry.executedQuantity}`],
                  textColor: 'rgba(255, 255, 255, 1)',
                  bodyColor: 'rgba(253, 224, 71, 0.8)'
                }
              })
            } else if (entry.status === 'canceled') {
              // time: entry.canceledAt,
              const canceledCandle = candles.value[trade.entryTime + 1800000]

              data.value.add('onchart', {
                id: 'trade-entry',
                name: `trade-entry-canceled-${entry.id}`,
                type: 'LabelBox',
                data: [],
                settings: {
                  time: canceledCandle[0],
                  price: canceledCandle[2] * 1.01,
                  text: [(entry.side === 'long' ? 'buy' : 'sell') + ':cancel', `p=${entry.quantity}`, `q=${entry.quantity}`],
                  textColor: 'rgba(255, 255, 255, 1)',
                  bodyColor: 'rgba(253, 224, 71, 0.8)'
                }
              })
            }
          }

          for (const takeProfit of trade.takeProfits) {

          }

          for (const stopLoss of trade.stopLosses) {

          }
        }

        // if (trade.closed) {
        //
        //   // TODO определить на основании ширины графика
        //   const candlesMaxCount = 30
        //   const candlesDiff = getTimeframeDiff(backtest.value.timeframe)
        //
        //   const candlesBetween = Math.ceil((trade.exitTime - trade.signalTime) / candlesDiff)
        //   const candlesAdditional = Math.floor((candlesMaxCount - candlesBetween) / 2)
        //
        //   let t1 = trade.signalTime - candlesAdditional * candlesDiff
        //   let t2 = trade.exitTime + candlesAdditional * candlesDiff
        //
        //   // data.value.del('onchart.Splitters')
        //   data.value.del('onchart.trade-setup')
        //   data.value.del('onchart.trade-setup-times')
        //   data.value.del('onchart.trade-entry-line')
        //   data.value.del('onchart.trade-take-profit-line')
        //   data.value.del('onchart.trade-stop-loss-line')
        //
        //   data.value.add('onchart', {
        //     "id": "trade-entry-line",
        //     "name": "trade-entry-line",
        //     "type": "Segment",
        //     "data": [],
        //     "settings": {
        //       "p1": [trade.signalTime - 10000000000, trade.entry],
        //       "p2": [trade.exitTime + 10000000000, trade.entry],
        //       "color": "#ffd33f"
        //     }
        //   })
        //   data.value.add('onchart', {
        //     "id": "trade-take-profit-line",
        //     "name": "trade-take-profit-line",
        //     "type": "Segment",
        //     "data": [],
        //     "settings": {
        //       "p1": [trade.signalTime - 10000000000, trade.takeProfit],
        //       "p2": [trade.exitTime + 10000000000, trade.takeProfit],
        //       "color": "#3a9d0f"
        //     }
        //   })
        //   data.value.add('onchart', {
        //     "id": "trade-stop-loss-line",
        //     "name": "trade-stop-loss-line",
        //     "type": "Segment",
        //     "data": [],
        //     "settings": {
        //       "p1": [trade.signalTime - 10000000000, trade.stopLoss],
        //       "p2": [trade.exitTime + 10000000000, trade.stopLoss],
        //       "color": "#d31a1a"
        //     }
        //   })
        //
        //   data.value.add('onchart', {
        //     "id": "trade-setup-times",
        //     "name": "trade-setup-times",
        //     "type": "Splitters",
        //     "data": [
        //       [
        //         trade.signalTime
        //       ],
        //       [
        //         trade.entryTime
        //       ],
        //       [
        //         trade.exitTime
        //       ]
        //     ],
        //     "settings": {
        //       "z-index": 0,
        //       "legend": false,
        //       "lineColor": "#cecece"
        //     }
        //   })
        //
        //   data.value.add('onchart', {
        //     "id": "trade-setup",
        //     "name": "Trades",
        //     "type": "Trades",
        //     "data": [
        //       [
        //         trade.entryTime,
        //         1,
        //         trade.entry
        //       ],
        //       [
        //         trade.exitTime,
        //         0,
        //         trade.exit,
        //         `${formatPercent(trade.profit)}%`
        //       ]
        //     ],
        //     "settings": {
        //       "z-index": 5,
        //       "legend": true,
        //       "labelColor": "#888"
        //     }
        //   })
        //
        //   chart.value.setRange(t1, t2)
        // }
      }
    })

    watchEffect(async () => {
      if (props.market && props.market.id && props.backtest && props.backtest.id) {
        setTimeout(() => {
          width.value = chartParent.value.clientWidth
        }, 100)

        const market = props.market
        const backtest = props.backtest

        const candleDiff = getTimeframeDiff(backtest.timeframe)
        const startedAt = backtest.startedAt
        const finishedAt = backtest.finishedAt
        const candlesTotal = Math.ceil((finishedAt - startedAt) / candleDiff)
        let candlesLoaded = 0

        console.info(`load backtest candles total=${candlesTotal}`)

        while (candlesLoaded < candlesTotal) {

          const from = candlesLoaded
          const size = 4000
          candlesLoaded += size

          console.info(`load batch from=${from} size=${size}`)

          const response = await apiService.loadCandles({
            exchange: market.exchange,
            type: market.type,
            base: market.base,
            quote: market.quote,
            timeframe: backtest.timeframe,
            started_at: backtest.startedAt,
            finished_at: backtest.finishedAt,
            from,
            size
          })

          console.info(`loaded candles size=${response.data.length}`)

          for (const candle of response.data) {
            candles.value[candle[0]] = candle
          }
        }

        data.value.set('chart.data', Object.values(candles.value).sort((d1, d2) => {
          return d1[0] - d2[0]
        }))

      }
    })


    const chartTitle = computed(() => {
      const backtest = props.backtest
      const market = props.market
      if (backtest && market && market.exchange) {
        return `${market.exchange.toUpperCase()}:${market.type.toUpperCase()}:${market.base.toUpperCase()}_${market.quote.toUpperCase()} ${backtest.timeframe}`
      } else {
        return 'Loading ...'
      }
    })

    const overlays = []

    return {
      chart,
      chartParent,
      width,
      height,
      data,
      overlays,
      chartTitle
    }
  }
}
</script>
