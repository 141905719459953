<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div class="rounded-tl-lg rounded-tr-lg bg-white px-4 pt-6 dark:bg-light-dark md:px-8 md:pt-8">
        <div
            class="flex flex-col items-center justify-between border-b border-dashed border-gray-200 pb-5 dark:border-gray-700 md:flex-row">
          <h2 class="mb-3 shrink-0 text-lg font-medium uppercase text-black dark:text-white sm:text-xl md:mb-0 md:text-2xl">
            Trade History</h2>
        </div>
      </div>
      <table class="transaction-table w-full border-separate border-0">
        <thead class="text-xs text-gray-500 dark:text-gray-300">
        <tr class="sticky top-20">
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Id
              </div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Type</div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Status</div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">PNL, <span class="uppercase">{{ market.quote }} / %</span>
              </div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Price, <span class="uppercase">{{
                  `${market.base}/${market.quote}`
                }}</span></div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Time</div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Fee, <span class="uppercase">{{ feeAsset }}</span>
              </div>
            </div>
          </th>
          <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Volume, <span class="uppercase">{{ market.quote }}</span></div>
            </div>
          </th>
          <th colspan="2"
              class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
            <div class="flex items-center">
              <div class="w-full text-center">Actions</div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody role="rowgroup" class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-xs">
        <template :key="trade.id" v-for="trade in sortedTrades()">
          <tr :class="{'bg-red-300': trade.closed && !trade.canceled && trade.pnl <= 0, 'bg-green-300': trade.closed && trade.pnl > 0, 'bg-yellow-300': !trade.closed, 'bg-gray-300': trade.canceled, 'font-bold border border-dashed border-black': trade === currentTrade}"
              class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center">{{ trade.id }}</div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center">{{ trade.type.toUpperCase() }}</div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center">{{ getTradeStatus(trade) }}</div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center"><span class="font-bold">{{
                    formatPrice(trade.pnl)
                  }} / {{ formatPercent(getPnlPercent(trade)) }}%</span></div>
              </div>
            </td>
            <td nowrap
                class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center">
                  <p class="font-mono text-xs">
                    entry=<span class="font-bold">{{ formatPrice(trade.entryPrice) }}</span>
                  </p>
                  <p class="font-mono text-xs">
                    exit=<span class="font-bold">{{ formatPrice(trade.exitPrice) }}</span>
                  </p>
                </div>
              </div>
            </td>
            <td nowrap
                class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center">
                  <p class="font-mono text-xs ">
                    signal=<span class="font-bold">{{ formatTime(trade.signalTime) }}</span>
                  </p>
                  <p class="font-mono text-xs">
                    entry=<span class="font-bold">{{ formatTime(trade.entryTime) }}</span>
                  </p>
                  <p class="font-mono text-xs">
                    exit=<span class="font-bold">{{ formatTime(trade.exitTime) }}</span>
                  </p>
                  <p class="font-mono text-xs">
                    bars=<span class="font-bold">{{ trade.bars }}</span>
                  </p>
                </div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center"> {{ formatFee(trade.fee) }}</div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center"> {{ getVolume(trade) }}</div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center">
                <div class="w-full text-center">
                  <DarkButton @click="setCurrentTrade(trade)" text="Chart"/>
                </div>
              </div>
            </td>
            <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
              <div class="flex items-center justify-end">
                <div class="flex items-center">
                  <div class="w-full text-center">
                    <DarkButton @click="showOrders(trade)" text="Orders"/>
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr v-if="trade.isShownOrders">
            <td class="pl-10 pr-10" colspan="10">
              <table class="transaction-table w-full border-separate border-0 mb-10">
                <thead class="text-xs text-gray-500 dark:text-gray-300">
                <tr>
                  <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
                    <div class="flex items-center">
                      <div class="w-full text-center">Id
                      </div>
                    </div>
                  </th>
                  <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
                    <div class="flex items-center">
                      <div class="w-full text-center">Type</div>
                    </div>
                  </th>
                  <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
                    <div class="flex items-center">
                      <div class="w-full text-center">Status
                      </div>
                    </div>
                  </th>
                  <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
                    <div class="flex items-center">
                      <div class="w-full text-center">Fee, <span class="uppercase">{{ feeAsset }}</span></div>
                    </div>
                  </th>
                  <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
                    <div class="flex items-center">
                      <div class="w-full text-center">Price, <span
                          class="uppercase">{{ `${market.base}/${market.quote}` }}</span></div>
                    </div>
                  </th>
                  <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
                    <div class="flex items-center">
                      <div class="w-full text-center">Quantity, <span class="uppercase">{{ market.quote }}</span>
                      </div>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody role="rowgroup" class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-xs">
                <tr :key="order.id" v-for="order in sortedOrders(trade)"
                    :class="{'bg-red-200': ['stop', 'stop_market'].indexOf(order.type) >= 0, 'bg-green-200': ['take_profit', 'take_profit_market'].indexOf(order.type) >= 0, 'bg-yellow-200': ['limit', 'market'].indexOf(order.type) >= 0}"
                    class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
                  <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                    <div class="flex items-center">
                      <div class="w-full text-center">{{ order.id }}</div>
                    </div>
                  </td>
                  <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                    <div class="flex items-center">
                      <div class="w-full text-center">{{ order.type.toUpperCase() }}</div>
                    </div>
                  </td>
                  <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                    <div class="flex items-center">
                      <div class="w-full text-center"><span class="uppercase">{{ order.status }}</span></div>
                    </div>
                  </td>
                  <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                    <div class="flex items-center">
                      <div class="w-full text-center">{{ formatFee(order.fee) }}</div>
                    </div>
                  </td>
                  <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                    <div class="flex items-center">
                      <div class="w-full text-center">
                        <p class="font-mono text-xs">
                          price=<span class="font-bold">{{ formatPrice(order.price) }}</span>
                        </p>
                        <p class="font-mono text-xs" v-if="order.stopPrice">
                          stopPrice=<span class="font-bold">{{ formatPrice(order.stopPrice) }}</span>
                        </p>
                        <p class="font-mono text-xs" v-if="order.averagePrice">
                          averagePrice=<span class="font-bold">{{ formatPrice(order.averagePrice) }}</span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                    <div class="flex items-center">
                      <div class="w-full text-center">
                        <p class="font-mono text-xs">
                          quantity=<span class="font-bold">{{ formatQuantity(order.quantity) }}</span>
                        </p>
                        <p class="font-mono text-xs" v-if="order.executedQuantity">
                          executedQuantity=<span class="font-bold">{{ formatQuantity(order.executedQuantity) }}</span>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>

        </template>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
import moment from 'moment/moment'
import Decimal from 'decimal.js'
import {computed, ref} from 'vue'
import DarkButton from '@/components/DarkButton'

export default {
  name: 'BacktestTrades',
  components: {
    DarkButton
  },
  emits: ['set-current-trade'],
  props: {
    trades: {
      type: Array,
      required: true
    },
    market: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {

    const currentTrade = ref({})

    const feeAsset = computed(() => {
      return props.trades.length ? props.trades[0].feeAsset : ''
    })

    const sortedTrades = () => {
      return props.trades ? props.trades.filter(t => t.closed).sort((t1, t2) => t2.signalTime - t1.signalTime) : []
    }

    const formatTime = (time) => {
      return moment(time).utc().format('DD.MM.YYYY HH:mm')
    }

    const formatFee = (price) => {
      return new Decimal(price || 0).toFixed(8)
    }

    const formatPrice = (price) => {
      return new Decimal(price || 0).toFixed(props.market ? props.market.pricePrecision : 0)
    }

    const formatQuantity = (quantity) => {
      return new Decimal(quantity || 0).toFixed(props.market ? props.market.quantityPrecision : 0)
    }

    const formatPercent = (percent) => {
      return new Decimal(percent || 0).toFixed(2)
    }

    const formatInteger = (number) => {
      return new Decimal(number || 0).toFixed(0)
    }

    const getVolume = (trade) => {
      return trade.entries.reduce((volume, entry) => {
        return volume + (entry.status === 'filled' ? entry.executedQuantity : 0)
      }, 0)
    }

    const getPnlPercent = (trade) => {
      const volume = getVolume(trade)
      if (volume > 0) {
        return 100.0 * trade.pnl / volume
      }
      return 0
    }

    const setCurrentTrade = (trade) => {
      currentTrade.value = trade

      emit('set-current-trade', trade)
    }

    const showOrders = (trade) => {
      trade.isShownOrders = !trade.isShownOrders
      return false
    }

    const getTradeStatus = (trade) => {
      if (trade.canceled) {
        return 'CANCELED'
      } else if (!trade.closed) {
        return 'OPEN'
      } else {
        const hasTakeProfit = trade.takeProfits.some(tp => tp.status === 'filled')
        const hasStopLoss = trade.stopLosses.some(sl => sl.status === 'filled')
        if (hasTakeProfit && hasStopLoss) {
          return 'MIXED'
        } else if (hasTakeProfit) {
          return 'TAKE_PROFIT'
        } else {
          return 'STOP_LOSS'
        }
      }
    }

    const sortedOrders = (trade) => {
      return [
        ...trade.entries.sort((t1, t2) => t1.id - t2.id),
        ...trade.takeProfits.sort((t1, t2) => t1.id - t2.id),
        ...trade.stopLosses.sort((t1, t2) => t1.id - t2.id)
      ]
    }

    return {
      currentTrade,
      getVolume,
      sortedOrders,
      sortedTrades,
      formatPrice,
      formatQuantity,
      formatFee,
      formatPercent,
      formatTime,
      formatInteger,
      setCurrentTrade,
      showOrders,
      feeAsset,
      getTradeStatus,
      getPnlPercent
    }
  }
}
</script>
