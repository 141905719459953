import axios from 'axios'
import endpoints from '@/services/endpoints'

axios.defaults.headers.post['Content-Type'] = 'application/json';

const http = axios.create({
  baseURL: endpoints.apiUrl,
  headers: {
    'Content-type': 'application/json'
  }
})
http.defaults.timeout = 0

export default http
