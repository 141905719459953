<template>
  <div
      class="os-host os-host-foreign os-theme-thin os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition os-host-overflow os-host-overflow-y"
      style="height:calc(100% - 96px)">
    <div class="os-padding">
      <div class="os-viewport os-viewport-native-scrollbars-invisible" style="overflow-y: scroll;">
        <div class="os-content" style="padding: 0px; height: 100%; width: 100%;">
          <div class="px-6 pb-5 2xl:px-8">
            <div class="mt-12">

              <MainMenuItem title="Dashboard" url="/">
                <HomeSvg/>
              </MainMenuItem>

              <MainMenuItem title="Trades" url="/trades">
                <MoneySvg/>
              </MainMenuItem>

              <MainMenuItem title="Bots" url="/bots">
                <ServersSvg/>
              </MainMenuItem>

              <MainMenuItem title="Strategies" url="/strategies">
                <PuzzleSvg/>
              </MainMenuItem>

              <MainMenuItem title="Backtests" url="/backtests">
                <ChartBarSvg/>
              </MainMenuItem>

              <MainMenuItem title="Scripts" url="/scripts">
                <VariableSvg/>
              </MainMenuItem>

              <MainMenuItem title="Accounts" url="/accounts">
                <IdentificationSvg/>
              </MainMenuItem>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenuItem from '@/components/MainMenuItem'
import HomeSvg from "@/components/icons/HomeSvg";
import MoneySvg from "@/components/icons/MoneySvg";
import IdentificationSvg from "@/components/icons/IdentificationSvg";
import PuzzleSvg from "@/components/icons/PuzzleSvg";
import ChartBarSvg from "@/components/icons/ChartBarSvg";
import VariableSvg from "@/components/icons/VariableSvg";
import ServersSvg from "@/components/icons/ServersSvg";

export default {
  name: 'MainMenu',
  components: {ServersSvg, VariableSvg, ChartBarSvg, PuzzleSvg, IdentificationSvg, MoneySvg, HomeSvg, MainMenuItem}
}
</script>
