const DEVELOPMENT_API_URL = '/api'
const PRODUCTION_API_URL = '/api'

const endpoints = {}
if (process.env.NODE_ENV === 'development') {
  endpoints.apiUrl = DEVELOPMENT_API_URL
} else {
  endpoints.apiUrl = PRODUCTION_API_URL
}

export default endpoints
