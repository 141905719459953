<template>
  <button @click="connect()" @mouseover="onMenuOver()" @mouseleave="onMenuLeave()"
          class="relative inline-flex shrink-0 items-center justify-center overflow-hidden text-center text-xs font-medium tracking-wider outline-none transition-all sm:text-sm bg-brand border-brand text-white rounded-full px-5 sm:px-8 h-10 sm:h-12 shadow-main">
    <span v-if="!user.address">Войти</span>
    <span v-if="user.address">{{ formattedAddress }}</span>
  </button>
  <div v-if="showMenu" @mouseover="onMenuOver()" @mouseleave="onMenuLeave()"
       class="absolute w-72 origin-top-right rounded-lg bg-white shadow-large dark:bg-gray-900"
       style="margin-top: 310px">
    <div class="border-b border-dashed border-gray-200 p-3 dark:border-gray-700" id="headlessui-menu-item-:rf:"
         role="menuitem" tabindex="-1" data-headlessui-state="">
      <router-link
          class="flex items-center gap-3 rounded-lg py-2.5 px-3 text-sm font-medium text-gray-900 transition hover:bg-gray-50 dark:text-white dark:hover:bg-gray-800 active"
          :to="'/profile'"><span
          class="h-8 w-8 rounded-full border-2 border-solid border-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 dark:border-gray-700"></span><span
          class="grow uppercase">View Your Profile</span>
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.231232 0.231232C0.53954 -0.0770772 1.03941 -0.0770772 1.34772 0.231232L5.55824 4.44176C5.86655 4.75007 5.86655 5.24993 5.55824 5.55824L1.34772 9.76877C1.03941 10.0771 0.53954 10.0771 0.231232 9.76877C-0.0770772 9.46046 -0.0770772 8.96059 0.231232 8.65228L3.88352 5L0.231232 1.34772C-0.0770772 1.03941 -0.0770772 0.53954 0.231232 0.231232Z"
                fill="currentColor"></path>
        </svg>
      </router-link>
    </div>
    <div class="border-b border-dashed border-gray-200 px-6 py-5 dark:border-gray-700" id="headlessui-menu-item-:rh:"
         role="menuitem" tabindex="-1" data-headlessui-state="">
      <div class="flex items-center justify-between gap-3"><span
          class="text-sm font-medium -tracking-tighter text-gray-600 dark:text-gray-400">Balance</span><span
          class="rounded-lg bg-gray-100 px-2 py-1 text-sm tracking-tighter dark:bg-gray-800">{{
          formattedAddress
        }}</span>
      </div>
      <div class="mt-3 font-medium uppercase tracking-wider text-gray-900 dark:text-white">1,000,000.00 SMTR
      </div>
    </div>
    <div class="p-3" id="headlessui-menu-item-:ri:" role="menuitem" tabindex="-1" data-headlessui-state="">
      <div @click="disconnect()"
           class="flex cursor-pointer items-center gap-3 rounded-lg py-2.5 px-3 text-sm font-medium text-gray-900 transition hover:bg-gray-50 dark:text-white dark:hover:bg-gray-800">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.55714 4.69417C6.55714 4.51664 6.37314 4.40018 6.2189 4.48807C4.82534 5.28213 3.88571 6.78133 3.88571 8.5C3.88571 11.0484 5.9516 13.1143 8.5 13.1143C11.0484 13.1143 13.1143 11.0484 13.1143 8.5C13.1143 6.78133 12.1747 5.28213 10.7811 4.48807C10.6269 4.40018 10.4429 4.51664 10.4429 4.69416V5.89287C10.4429 5.9676 10.4776 6.03774 10.5348 6.08593C11.2212 6.66507 11.6571 7.53162 11.6571 8.5C11.6571 10.2436 10.2436 11.6571 8.5 11.6571C6.75636 11.6571 5.34286 10.2436 5.34286 8.5C5.34286 7.53162 5.77885 6.66507 6.46524 6.08593C6.52235 6.03774 6.55714 5.9676 6.55714 5.89287V4.69417Z"
              fill="currentColor"></path>
          <path
              d="M9.22857 3.64286C9.22857 3.24048 8.90238 2.91429 8.5 2.91429C8.09762 2.91429 7.77143 3.24048 7.77143 3.64286V8.5C7.77143 8.90238 8.09762 9.22857 8.5 9.22857C8.90238 9.22857 9.22857 8.90238 9.22857 8.5V3.64286Z"
              fill="currentColor"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5ZM8.5 1.45714C4.61034 1.45714 1.45714 4.61034 1.45714 8.5C1.45714 12.3897 4.61034 15.5429 8.5 15.5429C12.3897 15.5429 15.5429 12.3897 15.5429 8.5C15.5429 4.61034 12.3897 1.45714 8.5 1.45714Z"
                fill="currentColor"></path>
        </svg>
        <span class="grow uppercase">Выйти</span></div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import apiService from '@/services/api.service'
import useEmitter from '@/composables/useEmitter'

export default {
  name: 'ConnectButton',
  setup() {

    const user = ref({})
    const formattedAddress = computed(() => {
      return user.value && user.value.address ? `${user.value.address.substring(0, 6)}...${user.value.address.substring(38)}` : ''
    })
    const showMenu = ref(false)
    const emitter = useEmitter()

    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      apiService.getUserInfo()
          .then(response => {
            user.value = response.data
            localStorage.setItem('user', JSON.stringify(user.value))
            emitter.emit('signIn', user.value)
          })
          .catch(e => {
            user.value = {}
          })
    }

    const hideTimeout = ref(null)

    const onMenuLeave = () => {
      if (user.value && user.value.address && showMenu.value) {
        if (hideTimeout.value) {
          clearTimeout(hideTimeout.value)
        }
        hideTimeout.value = setTimeout(() => {
          showMenu.value = false
        }, 500)
      }
    }

    const onMenuOver = () => {
      if (user.value && user.value.address && showMenu.value) {
        showMenu.value = true
        if (hideTimeout.value) {
          clearTimeout(hideTimeout.value)
          hideTimeout.value = null
        }
      }
    }

    const disconnect = () => {
      showMenu.value = false
      localStorage.removeItem('access_token')
      localStorage.removeItem('user')
      user.value = {}
      emitter.emit('signOut')
    }

    const connect = () => {
      if (user.value.address) {
        showMenu.value = true
      } else {

        const provider = window.ethereum
        provider.request({method: 'eth_requestAccounts'})
            .then((data) => {
              const address = data[0]

              apiService.getNonce()
                  .then(response => {
                    const nonce = response.data.nonce

                    provider.request({
                      method: 'personal_sign',
                      params: [address, nonce]
                    }).then((signature) => {
                      apiService.signIn({
                        address,
                        nonce,
                        signature
                      }).then(response => {
                        localStorage.setItem('access_token', response.data.access_token)

                        apiService.getUserInfo().then(response => {
                          user.value = response.data
                          localStorage.setItem('user', JSON.stringify(user.value))

                          emitter.emit('signUp', user.value)
                        })
                      })
                    })
                  })
            })
      }
    }

    return {
      user,
      showMenu,
      connect,
      disconnect,
      onMenuOver,
      onMenuLeave,
      formattedAddress
    }
  }
}
</script>
