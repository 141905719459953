<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">New bot</h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  </div>
  <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-10 mb-10" role="alert">
    <p class="font-bold mb-2">Error:</p>
    <p>{{ errorMessage }}</p>
  </div>
  <form class="w-full">
    <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Common</legend>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Name
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="bot.name" type="text">
        </div>
      </div>
      <div class="flex flex-wrap w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Status
          </label>
          <select v-model="bot.status"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>on</option>
            <option>off</option>
          </select>
        </div>
      </div>
    </fieldset>
    <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Market</legend>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            EXCHANGE
          </label>
          <select v-model="bot.exchange" @change="onChangeExchange()"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option v-for="exchange in exchanges" :key="exchange" :value="exchange"
                    :selected="bot.exchange === exchange">{{ exchange }}
            </option>
          </select>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            TYPE
          </label>
          <select v-model="bot.type" @change="onChangeType()"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option v-for="type in types" :key="type" :value="type" :selected="bot.type === type">{{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Markets
          </label>
          <vue-tags-input
              v-model="bot.market"
              :tags="bot.markets"
              :autocomplete-items="getMarketsAutocomplete"
              placeholder="All markets or specific"
              :add-only-from-autocomplete="true"
              @tags-changed="newTags => bot.markets = newTags"
          />
          <p class="text-gray-400 mt-2">If nothing is selected, then the bot works on all markets</p>
        </div>
      </div>
      <div v-if="currentStrategy.long || currentStrategy.short" class="flex flex-wrap w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Account
          </label>
          <select v-model="bot.account"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option :key="account.id" :value="account.id" :selected="bot.account.id === account.id"
                    v-for="account in accounts">{{ account.name }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Algorithm</legend>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Strategy
          </label>
          <select v-model="bot.strategy" @change="onStrategyChanged()"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option :key="strategy.id" :value="strategy.id" :selected="bot.strategy.id === strategy.id"
                    v-for="strategy in strategies">{{ strategy.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            TIMEFRAME
          </label>
          <select v-model="bot.timeframe"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>1m</option>
            <option>3m</option>
            <option>5m</option>
            <option>15m</option>
            <option>30m</option>
            <option>1h</option>
            <option>2h</option>
            <option>4h</option>
            <option>6h</option>
            <option>8h</option>
            <option>12h</option>
            <option>1d</option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            PARAMS
          </label>
          <textarea
              class="appearance-none min-h-[80px] block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              v-model="bot.params" placeholder="{}" rows="3"></textarea>
        </div>
      </div>
      <div v-if="currentStrategy.signal" class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3 mb-6 md:mb-0">
          <div class="flex items-center mb-4">
            <input v-model="bot.signalOverride" @change="onSignalOverrideChanged()" id="signal" type="checkbox"
                   class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="signal" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Signal
              override</label>
          </div>
        </div>
        <SignalEditor v-if="bot.signalOverride" :signal="bot.signal"/>
      </div>
      <div v-if="currentStrategy.long" class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3 mb-6 md:mb-0">
          <div class="flex items-center mb-4">
            <input v-model="bot.longOverride" @change="onLongOverrideChanged()" id="long" type="checkbox"
                   class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="long" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Long override</label>
          </div>
        </div>
        <LongEditor v-if="bot.longOverride" :long="bot.long"/>
      </div>
      <div v-if="currentStrategy.short" class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <div class="flex items-center mb-4">
            <input v-model="bot.shortOverride" @change="onShortOverrideChanged()" id="short" type="checkbox"
                   class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="short" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Short override</label>
          </div>
        </div>
        <ShortEditor v-if="bot.shortOverride" :short="bot.short"/>
      </div>
      <div v-if="currentStrategy.long || currentStrategy.short" class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Entry order type
          </label>
          <select v-model="bot.entryOrderType"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>limit</option>
            <option>market</option>
          </select>
        </div>
      </div>
      <div v-if="currentStrategy.long || currentStrategy.short" class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Take profit order type
          </label>
          <select v-model="bot.takeProfitOrderType"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>limit</option>
            <option>market</option>
          </select>
        </div>
      </div>
      <div v-if="currentStrategy.long || currentStrategy.short" class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Stop loss order type
          </label>
          <select v-model="bot.stopLossOrderType"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>limit</option>
            <option>market</option>
          </select>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="currentStrategy.long || currentStrategy.short"
              class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Money management</legend>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Size of each order
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="bot.size" type="number">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Count of open orders
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              v-model="bot.count" type="number">
        </div>
      </div>
      <div v-if="bot.type === 'futures'" class="flex flex-wrap w-full">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Leverage
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="bot.leverage" type="number">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Margin mode
          </label>
          <select v-model="bot.marginMode"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>isolated</option>
            <option>cross</option>
          </select>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="currentStrategy.long || currentStrategy.short"
              class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Risk management</legend>
      <div class="flex flex-wrap w-full">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Max number of losing orders for stop
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="bot.maxLosingTrades" type="number">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Max losing pnl for stop
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="bot.maxLosingPnl" type="number">
        </div>
      </div>
    </fieldset>
    <div class="mx-auto w-full">
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <LightButton text="Create" @click="create"/>
        <div v-if="loading" class="flex justify-center items-center p-5 w-full bg-white dark:bg-gray-900"
             data-component-loader="">
          <div role="status">
            <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"></path>
              <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"></path>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<script>
import LightButton from '@/components/LightButton'
import getLong from '@/composables/get-long'
import getShort from '@/composables/get-short'
import apiService from '@/services/api.service'
import VueTagsInput from '@sipec/vue3-tags-input'
import {computed, ref} from 'vue'
import {useRouter} from 'vue-router'
import SignalEditor from '@/components/forms/SignalEditor'
import LongEditor from '@/components/forms/LongEditor'
import ShortEditor from '@/components/forms/ShortEditor'
import getSignal from '@/composables/get-signal'

export default {
  name: 'CreateBotPage',
  components: {
    LongEditor,
    ShortEditor,
    SignalEditor,
    LightButton,
    VueTagsInput
  },
  setup() {
    const exchanges = ['binance', 'bybit']
    const types = ['futures', 'spot']
    const router = useRouter()
    const bot = ref({
      status: 'off',
      name: 'Название бота',
      exchange: 'binance',
      type: 'futures',
      market: '',
      markets: [],
      base: 'btc',
      quote: 'usdt',
      signalOverride: false,
      signal: getSignal(),
      longOverride: false,
      long: getLong(),
      shortOverride: false,
      short: getShort(),
      timeframe: '15m',
      size: 10,
      count: 3,
      leverage: 10,
      marginMode: 'cross',
      maxLosingTrades: 5,
      maxLosingPnl: 20,
      notification: ['email', 'telegram', 'web'],
      entryOrderType: 'limit',
      takeProfitOrderType: 'limit',
      stopLossOrderType: 'limit'
    })
    const strategies = ref([])
    const loading = ref(false)
    const errorMessage = ref(null)
    const accounts = ref([])
    const markets = ref([])
    const currentStrategy = ref({})

    const onStrategyChanged = () => {
      currentStrategy.value = strategies.value.find(s => s.id === bot.value.strategy)
      if (currentStrategy.value) {
        bot.value.params = JSON.stringify(currentStrategy.value.params)
        bot.value.signalOverride = false
        bot.value.signal = getSignal()
        bot.value.longOverride = false
        bot.value.long = getLong()
        bot.value.shortOverride = false
        bot.value.short = getShort()
      }
    }

    const onSignalOverrideChanged = () => {
      if (bot.value.signalOverride) {
        bot.value.signal = getSignal(currentStrategy.value.signal)
      } else {
        bot.value.signal = getSignal()
      }
    }

    const onLongOverrideChanged = () => {
      if (bot.value.longOverride) {
        bot.value.long = getLong(currentStrategy.value.long)
      } else {
        bot.value.long = getLong()
      }
    }

    const onShortOverrideChanged = () => {
      if (bot.value.shortOverride) {
        bot.value.short = getShort(currentStrategy.value.short)
      } else {
        bot.value.short = getShort()
      }
    }

    const loadMarkets = () => {
      apiService.getMarkets({
        exchange: bot.value.exchange,
        type: bot.value.type
      })
          .then(response => {
            markets.value = response.data
          })
    }
    loadMarkets()

    const onChangeExchange = () => {
      bot.value.markets = []
      loadMarkets()
    }

    const onChangeType = () => {
      bot.value.markets = []
      loadMarkets()
    }

    const create = () => {
      const botJson = {
        name: bot.value.name,
        status: bot.value.status,
        exchange: bot.value.exchange,
        type: bot.value.type,
        markets: bot.value.markets.map(m => {
          return m.id
        }),
        account: bot.value.account,
        timeframe: bot.value.timeframe,
        strategy: bot.value.strategy,
        params: JSON.parse(bot.value.params),
        entryOrderType: bot.value.entryOrderType,
        takeProfitOrderType: bot.value.takeProfitOrderType,
        stopLossOrderType: bot.value.takeProfitOrderType,
        size: bot.value.size,
        count: bot.value.count,
        leverage: bot.value.leverage,
        marginMode: bot.value.marginMode,
        maxLosingTrades: bot.value.maxLosingTrades,
        maxLosingPnl: bot.value.maxLosingPnl
      }

      if (bot.value.signalOverride && currentStrategy.value.signal) {
        botJson.signal = getSignal(bot.value.signal)
      }

      if (bot.value.longOverride && currentStrategy.value.long) {
        botJson.long = getLong(bot.value.long)
      }

      if (bot.value.shortOverride) {
        botJson.short = getShort(bot.value.short)
      }

      if (botJson.status === 'on') {
        if (!confirm('Создать бота со статусом "Включен"?')) {
          return
        }
      }

      loading.value = true
      errorMessage.value = null

      apiService.createBot(botJson)
          .then(response => {
            router.push(`/bots/${response.data.id}`)
          })
          .catch(error => {
            loading.value = false
            errorMessage.value = error
          })
    }

    apiService.getAccounts()
        .then(response => {
          accounts.value = response.data

          const account = accounts.value[0]
          if (account) {
            bot.value.account = account.id
          }
        })

    apiService.getStrategies()
        .then(response => {
          strategies.value = response.data

          currentStrategy.value = strategies.value[0]
          if (currentStrategy.value) {
            bot.value.strategy = currentStrategy.value.id
            bot.value.params = JSON.stringify(currentStrategy.value.params)
          }
        })

    const getMarketsAutocomplete = computed(() => {
      return markets.value
          .filter(market => {
            return market.symbol.startsWith(bot.value.market)
          })
          .map(market => {
            return {
              id: market.id,
              text: market.symbol
            }
          })
    })

    return {
      exchanges,
      types,
      strategies,
      accounts,
      bot,
      loading,
      errorMessage,
      create,
      onStrategyChanged,
      onSignalOverrideChanged,
      onLongOverrideChanged,
      onShortOverrideChanged,
      markets,
      getMarketsAutocomplete,
      onChangeExchange,
      onChangeType,
      currentStrategy
    }
  }
}
</script>

<style>

.vue-tags-input {
  max-width: 100% !important;
  width: 100% !important;
  background: rgb(229 231 235/var(--tw-bg-opacity)) !important;
  border-radius: 4px;
}

.ti-input {
  padding: 8px !important;
  border: 0 !important;
}

.ti-new-tag-input {
  background: rgb(229 231 235/var(--tw-bg-opacity)) !important;
}
</style>

