export default (signal) => {
    if (signal) {
        const result = {
            when: signal.when
        }

        if (signal.email) {
            result.email = {
                target: signal.email.target,
                subject: signal.email.subject,
                message: signal.email.message
            }
        } else {
            result.email = {
                target: '',
                subject: '',
                message: ''
            }
        }

        if (signal.telegram) {
            result.telegram = {
                target: signal.telegram.target,
                message: signal.telegram.message
            }
        } else {
            result.telegram = {
                target: '',
                message: ''
            }
        }
        return result
    } else {
        return {
            when: '',
            email: {
                target: '',
                subject: '',
                message: ''
            },
            telegram: {
                target: '',
                message: ''
            }
        }
    }
}