<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">Multitests</h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">

    <table role="table" class="transaction-table w-full border-separate border-0">
      <thead class="text-sm text-gray-500 dark:text-gray-300">
      <tr>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">ID</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Created at</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Strategy</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Status</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Profit factor</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Backtests</div>
          </div>
        </th>
        <th colspan="2"
            class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Actions</div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
      <tr v-if="backtests.length === 0"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td colspan="8"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5 text-gray-400">
          Create backtest!
        </td>
      </tr>
      <tr :key="backtest.id" v-for="backtest in sortedBacktests"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              {{ backtest.id }}
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              {{ formatTime(backtest.createdAt) }}
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">{{ backtest.strategyName }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <span v-if="backtest.status === 'error'"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1">{{
                  backtest.status.toUpperCase()
                }}</span>
              <span v-if="backtest.status === 'new'"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 uppercase last:mr-0 mr-1">{{
                  backtest.status.toUpperCase()
                }}</span>
              <span v-if="backtest.status === 'done'"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 uppercase last:mr-0 mr-1">{{
                  backtest.status.toUpperCase()
                }}</span>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">{{ formatPercent(backtest.profitFactor) }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">{{ backtest.children }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="View" @click="backtestView(backtest)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Delete" @click="backtestDelete(backtest)"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import apiService from '@/services/api.service'
import Decimal from 'decimal.js'
import moment from "moment";
import {useRouter} from 'vue-router'
import useEmitter from "@/composables/useEmitter";
import LightButton from "@/components/LightButton";

export default {
  name: 'BacktestsPage',
  components: {LightButton},
  setup() {
    const router = useRouter()
    const emitter = useEmitter()
    const backtests = ref([])
    const strategies = new Map()

    const formatPercent = (percent) => {
      return new Decimal(percent || 0).toFixed(2)
    }

    const formatTime = (time) => {
      return moment(time).utc().format('DD.MM.YYYY HH:mm')
    }

    const backtestView = (backtest) => {
      router.push(`/backtests/${backtest.id}/status`)
    }

    const backtestDelete = (backtest) => {
      if (confirm('Delete backtest?')) {
        apiService.deleteBacktests(backtest.id)
            .then(() => {
              backtests.value = backtests.value.filter(b => b.id !== backtest.id)
              alert('Backtest deleted')
            })
      }
    }

    const loadBacktests = () => {
      apiService.getBacktests({type: 'group'})
          .then(async (response) => {
            backtests.value = response.data

            for (const backtest of backtests.value) {
              if (!strategies.has(backtest.strategy)) {
                const strategy = (await apiService.getStrategy(backtest.strategy)).data
                if (strategy) {
                  strategies.set(backtest.strategy, strategy)
                }
              }
              const strategy = strategies.get(backtest.strategy)
              if (strategy) {
                backtest.strategyName = strategy.name
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
    }

    const sortedBacktests = computed(() => {
      return Array.from(backtests.value).sort((s1, s2) => s2.id - s1.id)
    })

    loadBacktests()

    emitter.on('signIn', loadBacktests)
    emitter.on('signUp', loadBacktests)
    emitter.on('signOut', loadBacktests)

    return {
      backtests,
      backtestView,
      backtestDelete,
      formatTime,
      formatPercent,
      sortedBacktests
    }
  }
}
</script>

