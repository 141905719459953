<script>

import Overlay from '../../mixins/overlay.js'

const drawLabelBox = (
    ctx,
    x,
    y,
    width,
    height,
    borderThickness,
    borderColor,
    fillColor,
    textFont,
    textHeight,
    textColor,
    textLines,
    pointerSize,
    pointerPosition,
    borderRadius) => {
  const offsetX = x - width / 2
  const offsetY = y - (height + pointerSize)

  // Draw the speech box
  ctx.beginPath()
  ctx.moveTo(offsetX + borderRadius, offsetY)
  ctx.lineTo(offsetX + width - borderRadius, offsetY)
  ctx.quadraticCurveTo(offsetX + width, offsetY, offsetX + width, offsetY + borderRadius)
  ctx.lineTo(offsetX + width, offsetY + height - borderRadius)
  ctx.quadraticCurveTo(offsetX + width, offsetY + height, offsetX + width - borderRadius, offsetY + height)
  if (pointerPosition === 'bottom') {
    ctx.lineTo(offsetX + width / 2 + pointerSize, offsetY + height)
    ctx.lineTo(offsetX + width / 2, offsetY + height + pointerSize)
    ctx.lineTo(offsetX + width / 2 - pointerSize, offsetY + height)
  } else {
    ctx.lineTo(offsetX + width / 2 - pointerSize, offsetY)
    ctx.lineTo(offsetX + width / 2, offsetY - pointerSize)
    ctx.lineTo(offsetX + width / 2 + pointerSize, offsetY)
  }
  ctx.lineTo(offsetX + borderRadius, offsetY + height)
  ctx.quadraticCurveTo(offsetX, offsetY + height, offsetX, offsetY + height - borderRadius)
  ctx.lineTo(offsetX, offsetY + borderRadius)
  ctx.quadraticCurveTo(offsetX, offsetY, offsetX + borderRadius, offsetY)
  ctx.closePath()

  ctx.lineWidth = borderThickness
  ctx.strokeStyle = borderColor
  ctx.fillStyle = fillColor
  ctx.fill()
  ctx.stroke()

  // Draw the text
  ctx.font = textFont
  ctx.fillStyle = textColor
  ctx.textAlign = "center"
  for (let i = 0; i < textLines.length; i++) {
    ctx.fillText(textLines[i], offsetX + width / 2, offsetY + textHeight + i * textHeight)
  }
}

export default {
  name: 'LabelBox',
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {author: 'Smarttrading.tech', version: '1.0.0'}
    },
    draw(ctx) {
      // data
      const text = this.$props.settings.text

      if (text && text.length) {
        // settings
        const time = this.$props.settings.time
        const price = this.$props.settings.price
        const textColor = this.$props.settings.textColor
        const textFont = this.$props.settings.textFont || '11px ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
        const textHeight = this.$props.settings.textHeight || 12
        const charWidth = this.$props.settings.charWidth || 7
        const bodyColor = this.$props.settings.bodyColor
        const borderRadius = this.$props.settings.borderRadius || 3
        const pointerSize = this.$props.settings.pointerSize || 3
        const pointerPosition = this.$props.settings.pointerPosition || 'bottom'

        let layout = this.$props.layout
        let offsetX = layout.t2screen(time)
        let offsetY = layout.$2screen(price)

        const maxTextLength = text.reduce((maxLength, string) => {
          if (string.length > maxLength) {
            return string.length
          } else {
            return maxLength
          }
        }, text[0].length)
        const width = borderRadius * 2 + maxTextLength * charWidth
        const height = borderRadius * 2 + text.length * textHeight

        drawLabelBox(
            ctx,
            offsetX,
            offsetY,
            width,
            height,
            0,
            bodyColor,
            bodyColor,
            textFont,
            textHeight,
            textColor,
            text,
            pointerSize,
            pointerPosition,
            borderRadius
        )
      } else {
        console.log('props: ', this.$props)
      }
    },
    use_for() {
      return ['LabelBox']
    }
    // y_range() {
    //   var hi = -Infinity, lo = Infinity
    //   for (var i = 0, n = this.sub.length; i < n; i++) {
    //     let x = this.sub[i]
    //     if (x[2] > hi) hi = x[2]
    //     if (x[3] < lo) lo = x[3]
    //   }
    //   return [hi, lo]
    // }
  }
}
</script>
