<template>
  <BacktestHead :backtest="backtest" :version="version"/>
  <div class="flex flex-row grow-0">
    <div class="basis-1/4">
      <BacktestDepth :backtest="backtest" :version="version"/>
    </div>
    <div class="basis-1/2 grow">
      <div class="flex flex-col">
        <BacktestChartTools :backtest="backtest" :version="version"/>
        <BacktestChart :backtest="backtest" :version="version"/>
        <BacktestStrategy :backtest="backtest" :version="version"/>
      </div>
    </div>
    <div class="basis-1/4 grow-0">
      <div class="flex flex-col">
        <BacktestTrades :backtest="backtest" :version="version"/>
        <BacktestTradeList :backtest="backtest" :version="version"/>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import BacktestHead from '@/components/backtest/BacktestHead'
import BacktestDepth from '@/components/backtest/BacktestDepth'
import BacktestChartTools from '@/components/backtest/BacktestChartTools'
import BacktestChart from '@/components/backtest/BacktestChart'
import BacktestStrategy from '@/components/backtest/BacktestStrategy'
import BacktestTrades from '@/components/backtest/BacktestTrades'
import BacktestTradeList from '@/components/backtest/BacktestTradeList'
import apiService from '@/services/api.service'

export default {
  name: 'BacktestVersionPage',
  components: {
    BacktestHead,
    BacktestDepth,
    BacktestChartTools,
    BacktestChart,
    BacktestStrategy,
    BacktestTrades,
    BacktestTradeList
  },
  setup() {
    const route = useRoute()
    const backtest = ref({})
    const version = ref({})

    const loadBacktest = () => {
      apiService.getBacktest(route.params.id)
          .then(async (response) => {
            backtest.value = response.data
            version.value = response.data
          })
    }
    loadBacktest()

    return {
      backtest,
      version
    }
  }
}
</script>

