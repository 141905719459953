<template>
  Trades here
</template>

<script>

export default {
  name: 'TradesPage',
  components: {},
  setup() {

  }
}
</script>

