const getTimeframeDiff = (timeframe) => {
    switch (timeframe) {
        case '1d':
            return 86400 * 1000
        case '12h':
            return 43200 * 1000
        case '8h':
            return 28800 * 1000
        case '6h':
            return 21600 * 1000
        case '4h':
            return 14400 * 1000
        case '2h':
            return 7200 * 1000
        case '1h':
            return 3600 * 1000
        case '30m':
            return 1800 * 1000
        case '15m':
            return 900 * 1000
        case '5m':
            return 300 * 1000
        case '3m':
            return 180 * 1000
        case '1m':
            return 60 * 1000

        default:
            return 0
    }
}

export {
    getTimeframeDiff
}
