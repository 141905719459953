export default (long) => {
    if (long) {
        return {
            isEntry: long.isEntry,
            entry: long.entry,
            takeProfit: long.takeProfit,
            stopLoss: long.stopLoss,
            maxTrades: long.maxTrades,
            maxCandles: long.maxCandles
        }
    } else {
        return {
            isEntry: '',
            entry: '',
            takeProfit: '',
            stopLoss: '',
            maxTrades: 0,
            maxCandles: 0
        }
    }
}