<template>
  <div>BacktestStrategy</div>
</template>

<script>
export default {
  name: 'BacktestStrategy',
  props: {
    backtest: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    }
  }
}
</script>
