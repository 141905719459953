<template>
  <div class="mb-2 min-h-[48px] list-none last:mb-0">
    <router-link v-if="!isActive" :to="url"
                 class="relative flex h-12 items-center whitespace-nowrap rounded-lg px-4 text-sm text-gray-500 transition-all hover:text-brand dark:hover:text-white">
      <span class="relative z-[1] ltr:mr-3 rtl:ml-3">
         <slot/>
      </span>
      <span class="relative z-[1]"><!-- -->{{ title }}<!-- --></span>
    </router-link>

    <router-link v-if="isActive" :to="url"
                 class="relative flex h-12 items-center whitespace-nowrap rounded-lg px-4 text-sm text-gray-500 transition-all hover:text-brand dark:hover:text-white  !text-white">
      <span class="relative z-[1] ltr:mr-3 rtl:ml-3">
        <slot/>
      </span>
      <span class="relative z-[1]"> <!-- -->{{ title }}<!-- --></span>
      <span class="absolute bottom-0 left-0 right-0 h-full w-full rounded-lg bg-brand shadow-large"
            style="opacity: 1;"></span>
    </router-link>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: 'MainMenuItem',
  props: {
    title: String,
    url: String
  },
  setup(props) {

    const route = useRoute()

    const isActive = computed(() => {
      return props.url === route.path.toLowerCase()
    })

    return {
      isActive
    }
  }
}
</script>
