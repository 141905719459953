<template>
  <nav
      class="sticky top-0 z-30 h-16 w-full transition-all duration-300 ltr:right-0 rtl:left-0 sm:h-20 3xl:h-24 bg-gradient-to-b from-white to-white/80 shadow-card backdrop-blur dark:from-dark dark:to-dark/80">
    <div class="flex h-full items-center justify-between px-4 sm:px-6 lg:px-8 xl:px-10 3xl:px-12">
      <div class="flex items-center">
        <!--        <div class="block ltr:mr-1 rtl:ml-1 ltr:sm:mr-3 rtl:sm:ml-3 xl:hidden">-->
        <!--          <button-->
        <!--              class="relative inline-flex shrink-0 items-center justify-center overflow-hidden text-center text-xs font-medium tracking-wider outline-none transition-all sm:text-sm text-brand hover:bg-gray-100 dark:hover:bg-gray-800 focus:bg-gray-100 dark:focus:bg-gray-800  bg-transparent hover:bg-gray-50 dark:hover:bg-gray-800 rounded-full h-10 w-10 sm:w-12 sm:h-12 dark:text-white"-->
        <!--              aria-label="Hamburger"><span class=""><svg class="sm:w-auo h-auto w-6" width="30" height="30"-->
        <!--                                                         viewBox="0 0 100 100"><path-->
        <!--              class="ease-[cubic-bezier(0.4, 0, 0.2, 1)] transition-[stroke-dasharray,stroke-dashoffset] duration-[600ms]"-->
        <!--              fill="none" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-dasharray="60 207"-->
        <!--              stroke-dashoffset="0"-->
        <!--              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path><path-->
        <!--              class="ease-[cubic-bezier(0.4, 0, 0.2, 1)] transition-[stroke-dasharray,stroke-dashoffset] duration-[600ms]"-->
        <!--              fill="none" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-dasharray="60 60"-->
        <!--              stroke-dashoffset="0" d="M 20,50 H 80"></path><path-->
        <!--              class="ease-[cubic-bezier(0.4, 0, 0.2, 1)] transition-[stroke-dasharray,stroke-dashoffset] duration-[600ms]"-->
        <!--              fill="none" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-dasharray="60 207"-->
        <!--              stroke-dashoffset="0"-->
        <!--              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path></svg></span>-->
        <!--          </button>-->
        <!--        </div>-->
        <!--        <button-->
        <!--            class="relative inline-flex shrink-0 items-center justify-center overflow-hidden text-center text-xs font-medium tracking-wider outline-none transition-all sm:text-sm text-brand hover:bg-gray-100 dark:hover:bg-gray-800 focus:bg-gray-100 dark:focus:bg-gray-800  bg-transparent hover:bg-gray-50 dark:hover:bg-gray-800 rounded-full h-10 w-10 sm:w-12 sm:h-12 dark:text-white"-->
        <!--            aria-label="Search">-->
        <!--          <span>-->
        <!--          <svg width="16" height="16" viewBox="0 0 18 18" fill="none"-->
        <!--               xmlns="http://www.w3.org/2000/svg"-->
        <!--               class="h-auto w-3.5 sm:w-auto">-->
        <!--            <path fill-rule="evenodd"-->
        <!--                  clip-rule="evenodd"-->
        <!--                  d="M11.3851 12.4457C8.73488 14.5684 4.85544 14.4013 2.39866 11.9445C-0.237379 9.3085 -0.237379 5.03464 2.39866 2.3986C5.0347 -0.23744 9.30856 -0.23744 11.9446 2.3986C14.4014 4.85538 14.5685 8.73481 12.4458 11.3851L17.6014 16.5407C17.8943 16.8336 17.8943 17.3085 17.6014 17.6014C17.3085 17.8943 16.8337 17.8943 16.5408 17.6014L11.3851 12.4457ZM3.45932 10.8839C1.40907 8.83363 1.40907 5.50951 3.45932 3.45926C5.50957 1.40901 8.83369 1.40901 10.8839 3.45926C12.9327 5.50801 12.9342 8.82875 10.8885 10.8794C10.8869 10.8809 10.8854 10.8823 10.8839 10.8839C10.8824 10.8854 10.8809 10.8869 10.8794 10.8884C8.82882 12.9341 5.50807 12.9326 3.45932 10.8839Z"-->
        <!--                  fill="currentColor"></path>-->
        <!--          </svg>-->
        <!--          </span>-->
        <!--        </button>-->
      </div>
      <div class="relative order-last flex shrink-0 items-center gap-3 sm:gap-6 lg:gap-8">
        <router-link to="/trades">
          <div
              class="relative flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-100 bg-white text-brand shadow-main transition-all hover:-translate-y-0.5 hover:shadow-large focus:-translate-y-0.5 focus:shadow-large focus:outline-none dark:border-gray-700 dark:bg-light-dark dark:text-white sm:h-12 sm:w-12">
            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="h-auto w-3 sm:w-auto">
              <path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor"
                    d="M9.61379 0.309937C9.89044 0.427717 10.07 0.699327 10.07 1V7.99819H14C14.2667 7.99819 14.5133 8.13982 14.6478 8.37018C14.7822 8.60053 14.7842 8.88494 14.653 9.11714L14.1609 9.988C12.2948 13.2906 9.9149 16.2752 7.11058 18.8298L6.31506 19.5544C6.0955 19.7545 5.7786 19.8061 5.50693 19.686C5.23526 19.566 5.06 19.297 5.06 19V12.0611H1C0.585786 12.0611 0.25 11.7254 0.25 11.3111C0.25 11.177 0.285225 11.051 0.346932 10.9421C2.10572 7.69064 4.3575 4.73117 7.02222 2.16895L8.80017 0.459375C9.0169 0.250976 9.33715 0.192157 9.61379 0.309937ZM2.28458 10.5611H5.81C6.22421 10.5611 6.56 10.8969 6.56 11.3111V17.2945C8.9786 15.009 11.0517 12.3827 12.7134 9.49819H9.32C8.90579 9.49819 8.57 9.1624 8.57 8.74819V2.76162L8.06188 3.25019C5.8111 5.4144 3.86782 7.87442 2.28458 10.5611Z"></path>
            </svg>
            <span class="absolute top-0 right-0 h-2.5 w-2.5 rounded-full bg-brand shadow-light sm:h-3 sm:w-3"></span>
          </div>
        </router-link>
        <ConnectButton/>
      </div>
    </div>
  </nav>
</template>

<script>

import ConnectButton from '@/components/ConnectButton'

export default {
  name: 'NavBar',
  components: {
    ConnectButton
  }
}
</script>
