import http from './http.js'
import authHeader from './auth.headers.js'

class ApiService {

    getNonce() {
        return http.post('users/nonce', {})
    }

    signIn(params) {
        return http.post('users/signin', params)
    }

    getUserInfo() {
        return http.post('users', {}, {headers: authHeader()})
    }

    getStrategies() {
        return http.get('strategies', {headers: authHeader()})
    }

    getStrategy(id) {
        return http.get(`strategies/${id}`, {headers: authHeader()})
    }

    createStrategy(strategy) {
        return http.post('strategies', strategy, {headers: authHeader()})
    }

    updateStrategy(id, strategy) {
        return http.post(`strategies/${id}`, strategy, {headers: authHeader()})
    }

    deleteStrategy(strategy) {
        return http.delete(`strategies/${strategy}`, {headers: authHeader()})
    }

    getMarkets(params) {
        return http.get('markets', {
            params
        })
    }

    createBacktest(backtest) {
        return http.post('backtests', backtest, {headers: authHeader()})
    }

    createMultitest(backtest) {
        return http.post('backtests/multi', backtest, {headers: authHeader()})
    }

    getBacktest(id) {
        return http.get(`backtests/${id}`, {headers: authHeader()})
    }

    getBacktestTrades(id) {
        return http.get(`backtests/${id}/trades`, {headers: authHeader()})
    }

    getBacktestStatus(id) {
        return http.get(`backtests/${id}/status`, {headers: authHeader()})
    }

    getBacktests(params) {
        return http.get('backtests', {
            params,
            headers: authHeader()
        })
    }

    deleteBacktests(id, strategy) {
        return http.delete('backtests' + (id ? (`/${id}`) : ''), {
            data: {
                strategy
            },
            headers: authHeader()
        })
    }

    loadCandles(params) {
        return http.get('candles', {
            params,
            headers: authHeader()
        })
    }

    loadData(name, params) {
        return http.get(`candles/${name}`, {
            params,
            headers: authHeader()
        })
    }

    getScripts(params) {
        return http.get('scripts', {
            params,
            headers: authHeader()
        })
    }

    createScript(script) {
        return http.post('scripts', script, {headers: authHeader()})
    }

    updateScript(id, script) {
        return http.post(`scripts/${id}`, script, {headers: authHeader()})
    }

    getScript(id) {
        return http.get(`scripts/${id}`, {headers: authHeader()})
    }

    deleteScript(id) {
        return http.delete(`scripts/${id}`, {headers: authHeader()})
    }

    getBot(id) {
        return http.get(`bots/${id}`, {headers: authHeader()})
    }

    updateBot(id, bot) {
        return http.post(`bots/${id}`, bot, {headers: authHeader()})
    }

    startBot(id) {
        return http.post(`bots/${id}/start`, {}, {headers: authHeader()})
    }

    stopBot(id) {
        return http.post(`bots/${id}/stop`, {}, {headers: authHeader()})
    }

    startAllBots() {
        return http.post(`bots/all/start`, {}, {headers: authHeader()})
    }

    stopAllBots() {
        return http.post(`bots/all/stop`, {}, {headers: authHeader()})
    }

    createBot(bot) {
        return http.post('bots', bot, {headers: authHeader()})
    }

    getBots(params) {
        return http.get('bots', {
            params,
            headers: authHeader()
        })
    }

    deleteBot(id) {
        return http.delete(`bots/${id}`, {headers: authHeader()})
    }


    getAccounts(params) {
        return http.get('accounts', {
            params,
            headers: authHeader()
        })
    }

    createAccount(account) {
        return http.post('accounts', account, {headers: authHeader()})
    }

    updateAccount(id, account) {
        return http.post(`accounts/${id}`, account, {headers: authHeader()})
    }

    getAccount(id) {
        return http.get(`accounts/${id}`, {headers: authHeader()})
    }

    deleteAccount(id) {
        return http.delete(`accounts/${id}`, {headers: authHeader()})
    }

    getMarkets(params) {
        return http.get('markets', {
            params,
            headers: authHeader()
        })
    }

    getMarket(id) {
        return http.get(`markets/${id}`, {headers: authHeader()})
    }

    getTrades(params) {
        return http.get('trades', {
            params,
            headers: authHeader()
        })
    }

    getTrade(id) {
        return http.get(`trades/${id}`, {headers: authHeader()})
    }

    getOrders(params) {
        return http.get('orders', {
            params,
            headers: authHeader()
        })
    }

    getOrder(id) {
        return http.get(`orders/${id}`, {headers: authHeader()})
    }
}

export default new ApiService()
