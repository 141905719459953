<template>
  <div class="flex flex-wrap mb-6 w-full">
    <div class="w-full px-3">
      <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
        <legend class="text-sm uppercase">Signal editor</legend>
        <div class="flex flex-wrap mb-6 w-full">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              When
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="when" type="text">
          </div>
        </div>
        <div class="flex flex-wrap w-full">
          <div class="w-full px-3">
            <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
              <legend class="text-sm uppercase">Email</legend>
              <div class="flex flex-wrap mb-6 w-full">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Target
                  </label>
                  <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      v-model="emailTarget" type="text">
                </div>
              </div>
              <div class="flex flex-wrap mb-6 w-full">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Subject
                  </label>
                  <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      v-model="emailSubject" type="text">
                </div>
              </div>
              <div class="flex flex-wrap mb-6 w-full">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Message
                  </label>
                  <textarea
                      class="appearance-none min-h-[80px] block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="emailMessage" rows="9"></textarea>
                </div>
              </div>
            </fieldset>

            <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
              <legend class="text-sm uppercase">Telegram</legend>
              <div class="flex flex-wrap mb-6 w-full">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Target
                  </label>
                  <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      v-model="telegramTarget" type="text">
                </div>
              </div>
              <div class="flex flex-wrap mb-6 w-full">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Message
                  </label>
                  <textarea
                      class="appearance-none min-h-[80px] block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="telegramMessage" rows="9"></textarea>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

      </fieldset>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'

export default {
  name: 'SignalEditor',
  props: {
    signal: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const when = computed({
      get() {
        return props.signal.when
      },
      set(newVal) {
        emit('update:signal', {...props.signal, signal: {...props.signal, when: newVal}})
      }
    })

    const emailTarget = computed({
      get() {
        return props.signal.email.target
      },
      set(newVal) {
        emit('update:signal', {
          ...props.signal,
          signal: {...props.signal, email: {...props.signal.email, target: newVal}}
        })
      }
    })

    const emailSubject = computed({
      get() {
        return props.signal.email.subject
      },
      set(newVal) {
        emit('update:signal', {
          ...props.signal,
          signal: {...props.signal, email: {...props.signal.email, subject: newVal}}
        })
      }
    })

    const emailMessage = computed({
      get() {
        return props.signal.email.message
      },
      set(newVal) {
        emit('update:signal', {
          ...props.signal,
          signal: {...props.signal, email: {...props.signal.email, message: newVal}}
        })
      }
    })

    const telegramTarget = computed({
      get() {
        return props.signal.telegram.target
      },
      set(newVal) {
        emit('update:signal', {
          ...props.signal,
          signal: {...props.signal, telegram: {...props.signal.telegram, target: newVal}}
        })
      }
    })

    const telegramMessage = computed({
      get() {
        return props.signal.telegram.message
      },
      set(newVal) {
        emit('update:signal', {
          ...props.signal,
          signal: {...props.signal, telegram: {...props.signal.telegram, message: newVal}}
        })
      }
    })

    return {
      when,
      emailTarget,
      emailSubject,
      emailMessage,
      telegramTarget,
      telegramMessage
    }
  }
}
</script>
