<template>

  <nav class="flex mb-10" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li class="inline-flex items-center">
        <router-link :to="'/'"
                     class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
          <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
          </svg>
          Dashboard
        </router-link>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
          </svg>
          <router-link :to="'/backtests'"
                       class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
            Backtests
          </router-link>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
          </svg>
          <router-link :to="'/backtests/' + backtest.parent + '/status'"
                       class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
            Multitest {{ backtest.parent }}
          </router-link>
        </div>
      </li>
      <li aria-current="page">
        <div class="flex items-center">
          <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
          </svg>
          <span
              class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Backtest {{
              backtest.id
            }}</span>
        </div>
      </li>
    </ol>
  </nav>

  <div v-if="!backtest.summaries" class="mx-auto w-full">
    <div class="flex justify-center items-center p-5 w-full bg-white dark:bg-gray-900">
      <div role="status">
        <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"></path>
          <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"></path>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div v-if="backtest.summaries" class="mx-auto w-full">
    <div class="flex flex-wrap ">
      <h1 class="mb-3 text-2xl font-extrabold dark:text-white">Backtest <span class="font-bold">{{
          getBacktestName
        }}</span></h1>
      <div class="flex w-full">
        <div class="flex w-1/3">
          <div class="py-5 xl:py-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-900 dark:text-white">
              <span class="underline">Market</span>
            </div>
            <span
                class="mb-2 flex items-center text-sm tracking-tight text-gray-600 transition last:mb-0 dark:text-gray-400">
              {{ getMarketInfo }}
            </span>
            <span
                class="mb-2 flex items-center text-sm tracking-tight text-gray-600 transition last:mb-0 dark:text-gray-400"
            >{{ getMarketName }}</span>
          </div>
        </div>
        <div class="flex w-1/3">
          <div class="py-5 xl:py-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-900 dark:text-white">
              <span class="underline">Backtest dates</span>
            </div>
            <span
                class="mb-2 flex items-center text-sm tracking-tight text-gray-600 transition last:mb-0 dark:text-gray-400">
              {{ formatTime(backtest.startedAt) }}
            </span>
            <span
                class="mb-2 flex items-center text-sm tracking-tight text-gray-600 transition last:mb-0 dark:text-gray-400">
               {{ formatTime(backtest.finishedAt) }}
            </span>
          </div>
        </div>
        <div class="flex w-1/3">
          <div class="py-5 xl:py-6">
            <div class="mb-2 text-sm font-medium uppercase tracking-wider text-gray-900 dark:text-white">
              <span class="underline">Trading dates</span>
            </div>
            <span
                class="mb-2 flex items-center text-sm tracking-tight text-gray-600 transition last:mb-0 dark:text-gray-400">
              {{ formatTime(getFirstTradeTime()) }}
            </span>
            <span
                class="mb-2 flex items-center text-sm tracking-tight text-gray-600 transition last:mb-0 dark:text-gray-400">
               {{ formatTime(getLastTradeTime()) }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="backtest.summaries" class="grid gap-5 sm:grid-cols-2 md:grid-cols-3 3xl:!grid-cols-3 4xl:!grid-cols-4">
        <div
            class="relative overflow-hidden rounded-lg bg-white shadow-card transition-all duration-200 hover:shadow-large dark:bg-light-dark">
          <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Total</h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">Summary of all trades</p>
            </div>
            <div class="border-t border-gray-200">
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">profit factor</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                      :class="{'text-red-500': backtest.summaries.total.profitFactor < 1, 'text-yellow-500': backtest.summaries.total.profitFactor < 2 && backtest.summaries.total.profitFactor >= 1, 'text-green-500': backtest.summaries.total.profitFactor >= 2}">
                    {{ formatPercent(backtest.summaries.total.profitFactor) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">profitable</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {{ formatPercent(backtest.summaries.total.percentProfitable) }}%
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">trades</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="total closed">{{
                        backtest.summaries.total.totalClosedTrades
                      }}</span>
                    <span class="text-green-500 mr-5" title="total wins">{{
                        backtest.summaries.total.winningTrades
                      }}</span>
                    <span class="text-red-500" title="total loses">{{ backtest.summaries.total.losingTrades }}</span>
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">bars</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5"
                          title="avgBarsInTrades">{{ formatInteger(backtest.summaries.total.avgBarsInTrades) }}</span>
                    <span class="text-green-500 mr-5" title="avgBarsInWinningTrades">{{
                        formatInteger(backtest.summaries.total.avgBarsInWinningTrades)
                      }}</span>
                    <span class="text-red-500" title="avgBarsInLosingTrades">{{
                        formatInteger(backtest.summaries.total.avgBarsInLosingTrades)
                      }}</span>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">avg profit</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="avgTrade">{{
                        formatAverage(backtest.summaries.total.avgTrade)
                      }}</span>
                    <span class="text-green-500 mr-5" title="avgWinningTrade">{{
                        formatAverage(backtest.summaries.total.avgWinningTrade)
                      }}</span>
                    <span class="text-red-500"
                          title="avgLosingTrade">{{ formatAverage(backtest.summaries.total.avgLosingTrade) }}</span>
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">gross profit</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="text-green-500 mr-5" title="grossProfit">{{
                        formatTotal(backtest.summaries.total.grossProfit)
                      }}</span>
                    <span class="text-red-500" title="grossLoss">{{
                        formatTotal(backtest.summaries.total.grossLoss)
                      }}</span>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">total fee</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="avgTrade">{{
                        formatFee(backtest.summaries.total.totalFee)
                      }}</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div
            class="relative overflow-hidden rounded-lg bg-white shadow-card transition-all duration-200 hover:shadow-large dark:bg-light-dark">
          <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Longs</h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">Summary of long trades</p>
            </div>
            <div class="border-t border-gray-200">
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">Profit factor</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                      :class="{'text-red-500': backtest.summaries.longs.profitFactor < 1, 'text-yellow-500': backtest.summaries.longs.profitFactor < 2 && backtest.summaries.longs.profitFactor >= 1, 'text-green-500': backtest.summaries.longs.profitFactor >= 2}">
                    {{ formatPercent(backtest.summaries.longs.profitFactor) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">profitable</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {{ formatPercent(backtest.summaries.longs.percentProfitable) }}%
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">trades</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="total closed">{{
                        backtest.summaries.longs.totalClosedTrades
                      }}</span>
                    <span class="text-green-500 mr-5" title="total wins">{{
                        backtest.summaries.longs.winningTrades
                      }}</span>
                    <span class="text-red-500" title="total loses">{{ backtest.summaries.longs.losingTrades }}</span>
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">bars</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5"
                          title="avgBarsInTrades">{{ formatInteger(backtest.summaries.longs.avgBarsInTrades) }}</span>
                    <span class="text-green-500 mr-5" title="avgBarsInWinningTrades">{{
                        formatInteger(backtest.summaries.longs.avgBarsInWinningTrades)
                      }}</span>
                    <span class="text-red-500" title="avgBarsInLosingTrades">{{
                        formatInteger(backtest.summaries.longs.avgBarsInLosingTrades)
                      }}</span>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">avg profit</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="avgTrade">{{
                        formatAverage(backtest.summaries.longs.avgTrade)
                      }}</span>
                    <span class="text-green-500 mr-5" title="avgWinningTrade">{{
                        formatAverage(backtest.summaries.longs.avgWinningTrade)
                      }}</span>
                    <span class="text-red-500"
                          title="avgLosingTrade">{{ formatAverage(backtest.summaries.longs.avgLosingTrade) }}</span>
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">gross profit</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="text-green-500 mr-5" title="grossProfit">{{
                        formatTotal(backtest.summaries.longs.grossProfit)
                      }}</span>
                    <span class="text-red-500" title="grossLoss">{{
                        formatTotal(backtest.summaries.longs.grossLoss)
                      }}</span>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">total fee</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="avgTrade">{{
                        formatFee(backtest.summaries.longs.totalFee)
                      }}</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div
            class="relative overflow-hidden rounded-lg bg-white shadow-card transition-all duration-200 hover:shadow-large dark:bg-light-dark">
          <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Shorts</h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">Summary of short trades</p>
            </div>
            <div class="border-t border-gray-200">
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">Profit factor</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                      :class="{'text-red-500': backtest.summaries.shorts.profitFactor < 1, 'text-yellow-500': backtest.summaries.shorts.profitFactor < 2 && backtest.summaries.shorts.profitFactor >= 1, 'text-green-500': backtest.summaries.shorts.profitFactor >= 2}">
                    {{ formatPercent(backtest.summaries.shorts.profitFactor) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">profitable</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {{ formatPercent(backtest.summaries.shorts.percentProfitable) }}%
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">trades</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="total closed">{{
                        backtest.summaries.shorts.totalClosedTrades
                      }}</span>
                    <span class="text-green-500 mr-5" title="total wins">{{
                        backtest.summaries.shorts.winningTrades
                      }}</span>
                    <span class="text-red-500" title="total loses">{{ backtest.summaries.shorts.losingTrades }}</span>
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">bars</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5"
                          title="avgBarsInTrades">{{ formatInteger(backtest.summaries.shorts.avgBarsInTrades) }}</span>
                    <span class="text-green-500 mr-5" title="avgBarsInWinningTrades">{{
                        formatInteger(backtest.summaries.shorts.avgBarsInWinningTrades)
                      }}</span>
                    <span class="text-red-500" title="avgBarsInLosingTrades">{{
                        formatInteger(backtest.summaries.shorts.avgBarsInLosingTrades)
                      }}</span>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">avg profit</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="avgTrade">{{
                        formatAverage(backtest.summaries.shorts.avgTrade)
                      }}</span>
                    <span class="text-green-500 mr-5" title="avgWinningTrade">{{
                        formatAverage(backtest.summaries.shorts.avgWinningTrade)
                      }}</span>
                    <span class="text-red-500"
                          title="avgLosingTrade">{{ formatAverage(backtest.summaries.shorts.avgLosingTrade) }}</span>
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">gross profit</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="text-green-500 mr-5" title="grossProfit">{{
                        formatTotal(backtest.summaries.shorts.grossProfit)
                      }}</span>
                    <span class="text-red-500" title="grossLoss">{{
                        formatTotal(backtest.summaries.shorts.grossLoss)
                      }}</span>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">total fee</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span class="font-bold mr-5" title="avgTrade">{{
                        formatFee(backtest.summaries.shorts.totalFee)
                      }}</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BacktestChart v-if="backtest && backtest.summaries" :backtest="backtest" :trade="currentTrade" :market="market"/>
    <BacktestTrades v-if="backtest && backtest.summaries" :trades="backtest.trades" :market="market"
                    @set-current-trade="setTrade"/>
  </div>
</template>

<script>
import {useRoute} from 'vue-router'
import apiService from '@/services/api.service'
import {computed, ref} from 'vue'
import moment from 'moment/moment.js'
import Decimal from 'decimal.js'
import BacktestTrades from '@/components/BacktestTrades'
import BacktestChart from '@/components/BacktestChart'

export default {
  name: 'BacktestPage',
  components: {
    BacktestChart,
    BacktestTrades
  },
  setup() {
    const route = useRoute()

    const backtest = ref({})
    const strategy = ref({})
    const market = ref({})
    const currentTrade = ref({})
    const pricePrecision = ref(0)

    const getBacktestName = computed(() => {
      return strategy.value.name
    })

    const getMarketName = computed(() => {
      if (market.value.id) {
        return (market.value.base + "_" + market.value.quote).toUpperCase()
      } else {
        return ''
      }
    })

    const getMarketInfo = computed(() => {
      if (market.value.id) {
        return (market.value.exchange + ":" + market.value.type + " " + backtest.value.timeframe).toUpperCase()
      } else {
        return ''
      }
    })

    const formatTime = (time) => {
      return moment(time).utc().format('DD.MM.YYYY HH:mm')
    }

    const formatFee = (fee) => {
      return new Decimal(fee || 0).toFixed(8)
    }

    const formatTotal = (total) => {
      return new Decimal(total || 0).toFixed(2)
    }

    const formatAverage = (average) => {
      return new Decimal(average || 0).toFixed(8)
    }

    const formatPercent = (percent) => {
      return new Decimal(percent || 0).toFixed(2)
    }

    const formatInteger = (number) => {
      return new Decimal(number || 0).toFixed(0)
    }

    const setTrade = (trade) => {
      currentTrade.value = trade

      setTimeout(() => {
        window.scrollTo({top: 800, behavior: 'smooth'})
      }, 100)
    }

    const loadBacktest = () => {
      apiService.getBacktest(route.params.id)
          .then(async (response) => {
            backtest.value = response.data

            if (!backtest.value.summaries) {
              setTimeout(loadBacktest, 1000)
            } else {
              apiService.getStrategy(backtest.value.strategy)
                  .then(async (response) => {
                    strategy.value = response.data

                    await apiService.getMarket(backtest.value.market)
                        .then(async (response) => {
                              market.value = response.data
                              pricePrecision.value = market.value.pricePrecision

                              await apiService.getBacktestTrades(backtest.value.id)
                                  .then(response => {
                                    backtest.value.trades = response.data
                                  })
                            }
                        )
                  })
            }
          })
    }
    loadBacktest()

    const getFirstTradeTime = () => {
      if (backtest.value && backtest.value.trades && backtest.value.trades.length) {
        return backtest.value.trades
            .reduce((firstTime, trade) => {
              if (trade.closed && trade.entryTime < firstTime) {
                return trade.entryTime
              }
              return firstTime
            }, backtest.value.trades[0].entryTime)
      }
      return 0
    }

    const getLastTradeTime = () => {
      if (backtest.value && backtest.value.trades && backtest.value.trades.length) {
        return backtest.value.trades
            .reduce((lastTime, trade) => {
              if (trade.closed && trade.entryTime > lastTime) {
                return trade.entryTime
              }
              return lastTime
            }, backtest.value.trades[0].entryTime)
      }
      return 0
    }

    return {
      formatTime,
      formatFee,
      formatTotal,
      formatAverage,
      formatPercent,
      formatInteger,
      setTrade,
      getFirstTradeTime,
      getLastTradeTime,
      getBacktestName,
      getMarketName,
      getMarketInfo,
      backtest,
      currentTrade,
      market
    }
  }
}
</script>

