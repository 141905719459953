<template>
  <div id="__next">
    <div class="ltr:xl:pl-72 rtl:xl:pr-72 ltr:2xl:pl-80 rtl:2xl:pr-80">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container'
}
</script>
