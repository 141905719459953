<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">Bots</h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <LightButton text="New bot" @click="createBot"/>
    </div>
  </div>
  <div class="mx-auto w-full">
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    <table role="table" class="transaction-table w-full border-separate border-0">
      <thead class="text-sm text-gray-500 dark:text-gray-300">
      <tr>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Name</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Candles</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Timeframe</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Trades</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">PNL</div>
          </div>
        </th>
        <th colspan="4" class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Actions</div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
      <tr v-if="bots.length === 0"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td colspan="9"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5 text-gray-400">
          Create bot
        </td>
      </tr>
      <tr :key="bot.id" v-for="bot in bots"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full"> {{ bot.name }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">{{ getMarket(bot) }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">{{ bot.timeframe }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">...</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">...</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton :text="bot.status === 'on' ? 'Stop' : 'Start'" @click="startOrStop(bot)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">
              <LightButton text="View" @click="viewBot(bot)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Edit" @click="editBot(bot)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Delete" @click="deleteBot(bot)"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue'
import apiService from '@/services/api.service'
import {useRouter} from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import LightButton from "@/components/LightButton";

export default {
  name: 'BotsPage',
  components: {
    LightButton
  },
  setup() {
    const router = useRouter()
    const bots = ref([])
    const emitter = useEmitter()

    const createBot = () => {
      router.push(`/bots/create`)
    }

    const getMarket = (bot) => {
      let markets
      if (bot.markets.length === 0) {
        markets = ' (all markets)'
      } else {
        markets = ` (${bot.markets.length} markets)`
      }

      return `${bot.exchange}:${bot.type} ${markets}`
    }

    const viewBot = (bot) => {
      router.push(`/bots/${bot.id}`)
    }

    const editBot = (bot) => {
      router.push(`/bots/${bot.id}/edit`)
    }

    const startOrStopPressed = ref(false)
    const startOrStop = (bot) => {
      if (startOrStopPressed.value) {
        console.log('start or stop already pressed')
        return
      }
      startOrStopPressed.value = true

      if (bot.status === 'on') {
        if (confirm(`Остановить бота ${bot.name}?`)) {
          apiService.stopBot(bot.id)
              .then(() => {
                console.log('successfully stopped bot')
                bot.status = 'off'
                startOrStopPressed.value = false
              })
              .catch((error) => {
                console.log('stop bot error: ', error)
                startOrStopPressed.value = false
                alert('stop bot error: ' + error)
              })
        }
      } else if (bot.status === 'off') {
        if (confirm(`Запустить бота ${bot.name}?`)) {
          apiService.startBot(bot.id)
              .then(() => {
                console.log('successfully started bot')
                bot.status = 'on'
                startOrStopPressed.value = false
              })
              .catch((error) => {
                console.log('start bot error: ', error)
                startOrStopPressed.value = false
                alert('start bot error: ' + error)
              })
        }
      }
    }

    const deleteBotPressed = ref(false)
    const deleteBot = (bot) => {
      if (deleteBotPressed.value) {
        console.log('delete bot already pressed')
        return
      }
      deleteBotPressed.value = true

      if (confirm(`Удалить бота ${bot.name}?`)) {
        apiService.deleteBot(bot.id)
            .then(() => {
              alert(`Бот ${bot.name} удален`)
              bots.value = bots.value.filter(b => b.id !== bot.id)
              deleteBotPressed.value = false
            })
            .catch((error) => {
              console.log('delete bot error: ', error)
              deleteBotPressed.value = false
              alert('delete bot error: ' + error)
            })
      }
    }

    const loadBots = () => {
      apiService.getBots()
          .then(response => {
            bots.value = response.data
          })
    }

    loadBots()

    emitter.on('signIn', loadBots)
    emitter.on('signUp', loadBots)
    emitter.on('signOut', loadBots)

    return {
      bots,
      createBot,
      viewBot,
      editBot,
      deleteBot,
      startOrStop,
      getMarket
    }
  }
}
</script>

