import HomePage from '@/pages/HomePage'
import MyPage from '@/pages/MyPage'
import StrategiesPage from '@/pages/StrategiesPage'
import BacktestsPage from '@/pages/BacktestsPage'
import BacktestPage from '@/pages/BacktestPage'
import CreateBacktestPage from '@/pages/CreateBacktestPage'
import CreateStrategyPage from '@/pages/CreateStrategyPage'
import UpdateStrategyPage from '@/pages/UpdateStrategyPage'
import ScriptsPage from '@/pages/ScriptsPage'
import CreateScriptPage from '@/pages/CreateScriptPage'
import UpdateScriptPage from '@/pages/UpdateScriptPage'
import BotsPage from '@/pages/BotsPage'
import CreateBotPage from '@/pages/CreateBotPage'
import UpdateBotPage from '@/pages/UpdateBotPage'
import BotPage from '@/pages/BotPage'
import TradesPage from '@/pages/TradesPage'
import CreateAccountPage from '@/pages/CreateAccountPage'
import UpdateAccountPage from '@/pages/UpdateAccountPage'
import AccountsPage from '@/pages/AccountsPage'
import BacktestStatusPage from '@/pages/BacktestStatusPage'
import BacktestVersionPage from "@/pages/BacktestVersionPage";

export default [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/my',
        component: MyPage
    },
    {
        path: '/strategies',
        component: StrategiesPage
    },
    {
        path: '/strategies/:id',
        component: UpdateStrategyPage
    },
    {
        path: '/strategies/create',
        component: CreateStrategyPage
    },
    {
        path: '/strategies/:id/backtest',
        component: CreateBacktestPage
    },
    {
        path: '/backtests',
        component: BacktestsPage
    },
    {
        path: '/backtests/:id',
        component: BacktestPage
    },
    {
        path: '/backtests/:id/version',
        component: BacktestVersionPage
    },
    {
        path: '/backtests/:id/status',
        component: BacktestStatusPage
    },
    {
        path: '/backtests/create',
        component: CreateBacktestPage
    },
    {
        path: '/scripts',
        component: ScriptsPage
    },
    {
        path: '/scripts/:id',
        component: UpdateScriptPage
    },
    {
        path: '/scripts/create',
        component: CreateScriptPage
    },
    {
        path: '/accounts',
        component: AccountsPage
    },
    {
        path: '/accounts/:id',
        component: UpdateAccountPage
    },
    {
        path: '/accounts/create',
        component: CreateAccountPage
    },
    {
        path: '/trades',
        component: TradesPage
    },
    {
        path: '/bots',
        component: BotsPage
    },
    {
        path: '/bots/:id',
        component: BotPage
    },
    {
        path: '/bots/:id/edit',
        component: UpdateBotPage
    },
    {
        path: '/bots/create',
        component: CreateBotPage
    }
]
