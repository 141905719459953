import {createApp} from 'vue'
import App from './App.vue'
import './index.css'
import {createRouter, createWebHistory} from 'vue-router'
import routes from './routes'
import mitt from 'mitt'
import VueNumberInput from '@chenfengyuan/vue-number-input'

const emitter = mitt()

const router = createRouter({
    history: createWebHistory(),
    routes
})

const app = createApp(App)
    .use(router)
    .component(VueNumberInput.name, VueNumberInput)

app.config.globalProperties.emitter = emitter
app.mount('#app')
