<template>
  <h1 class="mb-10 text-2xl font-extrabold dark:text-white">Update account</h1>
  <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-10 mb-10" role="alert">
    <p class="font-bold mb-2">Error:</p>
    <p>{{ errorMessage }}</p>
  </div>
  <form class="w-full">
    <div class="flex flex-wrap mb-6 w-full">
      <div class="w-full px-3 mb-6 md:mb-0">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Name
        </label>
        <input name="name"
               class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
               v-model="account.name" type="text">
      </div>
    </div>
    <div class="flex flex-wrap mb-6 w-full">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          EXCHANGE
        </label>
        <select v-model="account.exchange"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option>binance</option>
        </select>
      </div>
      <div class="w-full md:w-1/2 px-3">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          TYPE
        </label>
        <select v-model="account.type"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option>futures</option>
        </select>
      </div>
    </div>
    <div class="flex flex-wrap mb-6 w-full">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          API KEY
        </label>
        <input name="apiKey"
               class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
               v-model="account.apiKey" type="text">
      </div>
      <div class="w-full md:w-1/2 px-3">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          API SECRET
        </label>
        <input name="apiSecret"
               class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
               v-model="account.apiSecret" type="password">
      </div>
    </div>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    <div class="flex flex-wrap -mx-3 mb-6 mt-10">
      <div class="w-full md:w-1/2 px-3">
        <LightButton text="Save" @click="updateAccount"/>
      </div>
    </div>
  </form>
</template>

<script>
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import apiService from '@/services/api.service'
import LightButton from '@/components/LightButton'

export default {
  name: 'UpdateAccountPage',
  components: {
    LightButton
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const loading = ref(false)
    const errorMessage = ref(null)
    const account = ref({
      name: '',
      exchange: 'binance',
      type: 'futures',
      apiKey: '',
      apiSecret: ''
    })

    const updateAccount = () => {
      loading.value = true
      errorMessage.value = null

      apiService.updateAccount(account.value.id, account.value)
          .then(response => {
            router.push(`/accounts`)
          })
          .catch(error => {
            loading.value = false
            errorMessage.value = error
          })
    }

    apiService.getAccount(route.params.id)
        .then(response => {
          account.value = response.data
        })

    return {
      updateAccount,
      loading,
      errorMessage,
      account
    }
  }
}
</script>
