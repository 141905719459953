<template>
  <div>BacktestDepth</div>
</template>

<script>
export default {
  name: 'BacktestDepth',
  props: {
    backtest: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    }
  }
}
</script>
