<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">Accounts</h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
      <LightButton text="New account" @click="createAccount"/>
    </div>
  </div>
  <div class="mx-auto w-full">
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    <table role="table" class="transaction-table w-full border-separate border-0">
      <thead class="text-sm text-gray-500 dark:text-gray-300">
      <tr>
        <th class="w-full group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Name</div>
          </div>
        </th>
        <th class="w-full group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full">Exchange</div>
          </div>
        </th>
        <th colspan="2"
            class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Actions</div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
      <tr v-if="accounts.length === 0"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td colspan="4"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5 text-gray-400">
          Create new account!
        </td>
      </tr>
      <tr :key="account.id" v-for="account in accounts"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">{{ account.name }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full">{{ `${account.exchange}:${account.type}` }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Edit" @click="editAccount(account)"/>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Delete" @click="deleteAccount(account)"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import apiService from '@/services/api.service'
import useEmitter from '@/composables/useEmitter'
import LightButton from '@/components/LightButton'

export default {
  name: 'AccountsPage',
  components: {
    LightButton
  },
  setup() {
    const router = useRouter()
    const accounts = ref([])
    const emitter = useEmitter()

    const createAccount = () => {
      router.push(`/accounts/create`)
    }

    const editAccount = (account) => {
      router.push(`/accounts/${account.id}`)
    }

    const deleteAccount = (account) => {
      if (confirm('Delete account?')) {
        apiService.deleteAccount(account.id)
            .then(() => {
              alert(`Account deleted`)
              accounts.value = accounts.value.filter(s => s.id !== account.id)
            })
            .catch(alert)
      }
    }

    const loadAccounts = () => {
      apiService.getAccounts()
          .then(response => {
            accounts.value = response.data
          })
    }
    loadAccounts()

    emitter.on('signIn', loadAccounts)
    emitter.on('signUp', loadAccounts)
    emitter.on('signOut', loadAccounts)

    return {
      accounts,
      createAccount,
      editAccount,
      deleteAccount
    }
  }
}
</script>

