export default function authHeader() {
    const accessToken = localStorage.getItem('access_token')

    if (accessToken) {
        return {
            'access-token': accessToken,
            'Content-type': 'application/json'
        }
    } else {
        return {
            'Content-type': 'application/json'
        }
    }
}
