<template>

  <div class="mx-auto w-full mb-10">
    <div
        class="relative overflow-hidden rounded-lg bg-white shadow-card transition-all duration-200 hover:shadow-large dark:bg-light-dark">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Multitest {{ backtest.id }}</h3>
        </div>
        <div class="border-t border-gray-200">
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span v-if="backtest.status === 'error'"
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1">{{
                    backtest.status.toUpperCase()
                  }}</span>
                <span v-if="backtest.status === 'new'"
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 uppercase last:mr-0 mr-1">{{
                    backtest.status.toUpperCase()
                  }}</span>
                <span v-if="backtest.status === 'done'"
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 uppercase last:mr-0 mr-1">{{
                    backtest.status.toUpperCase()
                  }}</span>
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Started at</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ formatTime(backtest.startedAt) }}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Finished at</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ formatTime(backtest.finishedAt) }}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Strategy</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ strategy.name }}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Backtests</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ backtest.children }}
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 uppercase last:mr-0 mr-1">New: {{
                    getNewChildrenCount
                  }}</span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 uppercase last:mr-0 mr-1">Done: {{
                    getDoneChildrenCount
                  }}</span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1">Error: {{
                    getErrorChildrenCount
                  }}</span>
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Created at</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ formatTime(backtest.createdAt) }}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Generation time</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <div v-if="backtest.status !== 'new'">{{ formatDuration(backtest.generationTime) }}</div>
                <div v-if="backtest.status === 'new'" class="text-left vl-parent" style="height: 24px;">
                  <loading :active="true"
                           :loader="'bars'"
                           :width="60"
                           :height="24"
                           :can-cancel="false"
                           :is-full-page="false"
                           :color="'rgb(255, 165, 0)'"
                  />
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div class="mx-auto w-full">
    <table role="table" class="transaction-table w-full border-separate border-0">
      <thead class="text-sm text-gray-500 dark:text-gray-300">
      <tr>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">ID</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Status</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Timeframe</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Market</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-left">Parameters</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Profit factor</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Trades</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Generation time</div>
          </div>
        </th>
        <th colspan="2" class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">Actions</div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
      <tr v-if="status.backtests.length === 0"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td colspan="10"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5 text-gray-400">
          Create backtest!
        </td>
      </tr>
      <tr :key="status.id" v-for="status in sortedStatuses"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              {{ status.id }}
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <span v-if="status.status === 'error'"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1">{{
                  status.status.toUpperCase()
                }}</span>
              <span v-if="status.status === 'new'"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 uppercase last:mr-0 mr-1">{{
                  status.status.toUpperCase()
                }}</span>
              <span v-if="status.status === 'done'"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 uppercase last:mr-0 mr-1">{{
                  status.status.toUpperCase()
                }}</span>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              {{ status.timeframe }}
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">{{ getMarketInfo(status) }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-left">
              <p :key="param.name" v-for="param in formatParams(status.params)">
                {{ param.name }}={{ param.value }}
              </p>
            </div>
          </div>
        </td>
        <td :colspan="status.status === 'new' ? 5 : 1"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div v-if="status.status !== 'new'" class="w-full text-center">
              <span v-if="status.profitFactor < backtest.profitFactor"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1">
                {{ formatPercent(status.profitFactor) }}
              </span>
              <span v-if="status.profitFactor >= backtest.profitFactor"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 uppercase last:mr-0 mr-1">
                {{ formatPercent(status.profitFactor) }}
              </span>
            </div>
            <div v-if="status.status === 'new'" class="w-full text-center vl-parent" style="height: 24px;">
              <loading :active="true"
                       :loader="'bars'"
                       :width="60"
                       :height="24"
                       :can-cancel="false"
                       :is-full-page="false"
                       :color="'rgb(255, 165, 0)'"
              />
            </div>
          </div>
        </td>
        <td v-if="status.status !== 'new'"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <div>
                <p class="font-bold">
                  closed={{ status.totalClosedTrades }}</p>
                <p class="text-green-600">
                  wining={{ status.winningTrades }}</p>
                <p class="text-red-600">
                  losing={{ status.losingTrades }}</p>
              </div>
            </div>
          </div>
        </td>
        <td v-if="status.status !== 'new'"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              {{ formatDuration(status.generationTime) }} мс
            </div>
          </div>
        </td>
        <td v-if="status.status !== 'new'"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton v-if="status.summaries && status.summaries.total" text="View" @click="backtestView(status)"/>
            </div>
          </div>
        </td>
        <td v-if="status.status !== 'new'"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <LightButton text="Delete" @click="backtestDelete(status)"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {computed, onUnmounted, ref} from 'vue'
import apiService from '@/services/api.service'
import Decimal from 'decimal.js'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import {useRoute, useRouter} from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import LightButton from "@/components/LightButton";

momentDurationFormatSetup(moment)

export default {
  name: 'BacktestStatusPage',
  components: {
    LightButton,
    Loading
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()
    const backtest = ref({})
    const strategy = ref({})
    const status = ref({
      backtests: []
    })

    const formatPercent = (percent) => {
      return new Decimal(percent || 0).toFixed(2)
    }

    const formatTime = (time) => {
      return moment(time).utc().format('DD.MM.YYYY HH:mm')
    }

    const backtestView = (backtest) => {
      router.push(`/backtests/${backtest.id}`)
    }

    const getMarketInfo = (backtest) => {
      return `${backtest.market.exchange}:${backtest.market.type}:${backtest.market.base.toUpperCase()}_${backtest.market.quote.toUpperCase()}`
    }

    const backtestDelete = (backtest) => {
      if (confirm('Удалить бэктест?')) {
        apiService.deleteBacktests(backtest.id)
            .then(loadStatus)
            .catch(console.error)
      }
    }

    const sortedStatuses = computed(() => {
      if (status.value && status.value.backtests && status.value.backtests.length) {
        return Array.from(status.value.backtests).sort((s1, s2) => {
          if (s1.profitFactor === null && s2.profitFactor === null) {
            return 0
          } else if (s1.profitFactor === null) {
            return 1
          } else if (s2.profitFactor === null) {
            return -1
          }
          return s2.profitFactor - s1.profitFactor
        })
      }
      return []
    })

    const getErrorChildrenCount = computed(() => {
      if (status.value && status.value.backtests) {
        return status.value.backtests.filter(b => b.status === 'error').length
      } else {
        return 0
      }
    })

    const getNewChildrenCount = computed(() => {
      if (status.value && status.value.backtests) {
        return status.value.backtests.filter(b => b.status === 'new').length
      } else {
        return 0
      }
    })

    const getDoneChildrenCount = computed(() => {
      if (status.value && status.value.backtests) {
        return status.value.backtests.filter(b => b.status === 'done').length
      } else {
        return 0
      }
    })

    const formatParams = (params) => {
      return Object.entries(params).map(p => {
        return {
          name: p[0],
          value: p[1]
        }
      }).sort((p1, p2) => p1.name.localeCompare(p2.name))
    }

    const formatDuration = (duration) => {
      return moment.duration(duration, 'milliseconds').format('h:mm:ss')
    }

    const updater = ref(null)

    onUnmounted(() => {
      if (updater.value) {
        clearInterval(updater.value)
      }
    })

    const loadStatus = () => {
      apiService.getBacktest(route.params.id)
          .then((response) => {
            backtest.value = response.data

            apiService.getStrategy(backtest.value.strategy)
                .then((response) => {
                  strategy.value = response.data
                })
                .catch((error) => {
                  console.error(error)
                })

            apiService.getBacktestStatus(backtest.value.id)
                .then((response) => {
                  status.value = response.data

                  if (status.value.status === 'new') {
                    if (!updater.value) {
                      updater.value = setInterval(loadStatus, 1000)
                    }
                  } else {
                    if (updater.value) {
                      clearInterval(updater.value)
                    }
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
          })
          .catch((error) => {
            console.error(error)
          })
    }

    loadStatus()

    emitter.on('signIn', loadStatus)
    emitter.on('signUp', loadStatus)
    emitter.on('signOut', loadStatus)

    return {
      backtest,
      strategy,
      status,
      backtestView,
      backtestDelete,
      formatTime,
      formatPercent,
      formatParams,
      formatDuration,
      getMarketInfo,
      getErrorChildrenCount,
      getNewChildrenCount,
      getDoneChildrenCount,
      sortedStatuses
    }
  }
}
</script>

