<template>
  <div class="flex flex-wrap mb-6 w-full">
    <div class="w-full px-3">
      <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
        <legend class="text-sm uppercase">Short editor</legend>
        <div class="flex flex-wrap mb-6 w-full">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Is entry script
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="isEntry" type="text">
          </div>
        </div>
        <div class="flex flex-wrap mb-6 w-full">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Entry script
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="entry" type="text">
          </div>
        </div>
        <div class="flex flex-wrap mb-6 w-full">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Take profit script
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="takeProfit" type="text">
          </div>
        </div>
        <div class="flex flex-wrap mb-6 w-full">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Stop loss script
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="stopLoss" type="text">
          </div>
        </div>
        <div class="flex flex-wrap mb-6 w-full">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Max trades
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="maxTrades" type="number" min="0" max="1000000" step="1">
          </div>
        </div>
        <div class="flex flex-wrap w-full">
          <div class="w-full px-3 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Max candles
            </label>
            <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                v-model="maxCandles" type="number" min="0" max="1000000" step="1">
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'

export default {
  name: 'ShortEditor',
  props: {
    short: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const isEntry = computed({
      get() {
        return props.short.isEntry
      },
      set(newVal) {
        emit('update:short', {...props.short, short: {...props.short, isEntry: newVal}})
      }
    })

    const entry = computed({
      get() {
        return props.short.entry
      },
      set(newVal) {
        emit('update:short', {...props.short, short: {...props.short, entry: newVal}})
      }
    })

    const takeProfit = computed({
      get() {
        return props.short.takeProfit
      },
      set(newVal) {
        emit('update:short', {...props.short, short: {...props.short, takeProfit: newVal}})
      }
    })

    const stopLoss = computed({
      get() {
        return props.short.stopLoss
      },
      set(newVal) {
        emit('update:short', {...props.short, short: {...props.short, stopLoss: newVal}})
      }
    })

    const maxTrades = computed({
      get() {
        return props.short.maxTrades
      },
      set(newVal) {
        emit('update:short', {...props.short, short: {...props.short, maxTrades: newVal}})
      }
    })

    const maxCandles = computed({
      get() {
        return props.short.maxCandles
      },
      set(newVal) {
        emit('update:short', {...props.short, short: {...props.short, maxCandles: newVal}})
      }
    })

    return {
      isEntry,
      entry,
      takeProfit,
      stopLoss,
      maxTrades,
      maxCandles
    }
  }
}
</script>
