<template>
  <!--  <div class="w-full mb-3 flex flex-row h-14 rounded-lg shadow-card transition-all duration-200 hover:shadow-large">-->
  <!--    <div class="basis-1/5 grow-0"></div>-->
  <!--    <div class="basis-3/5 grow"></div>-->
  <!--    <div class="basis-1/5 grow-0 p-5">-->
  <!--      <a href="#" class="text-xs underline underline-offset-2 text-gray-600 align-middle text-center">Version 11</a>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="w-full mb-3 flex flex-col md:flex-row h-14 rounded-lg shadow-card transition-all duration-200 hover:shadow-large">
    <div class="w-1/5 md:w-64 flex flex-col justify-center items-center grow-0">
      <h3 class="text-center">Column 1</h3>
    </div>
    <div class="w-3/5 md:w-64 flex flex-col justify-center items-center grow">
      <div class="flex flex-row">
        <div class="mx-2">Text Block 1</div>
        <div class="mx-2">Text Block 2</div>
      </div>
    </div>
    <div class="w-1/5 md:w-64 flex flex-col justify-center items-center grow-0">
      <a href="#" class="text-center">Link</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BacktestHead',
  props: {
    backtest: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    }
  }
}
</script>
