<template>
  <div>BacktestChartTools</div>
</template>

<script>
export default {
  name: 'BacktestChartTools',
  props: {
    backtest: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    }
  }
}
</script>
