<template>
  <div class="mx-auto w-full">
    <h1>{{ bot.name }}</h1>
  </div>
  <!--  <div class="mx-auto w-full">-->
  <!--    <div ref="chartParent" class="flex flex-wrap mt-10">-->
  <!--      <div class="flex w-full" v-if="isCompleted">-->
  <!--        <trading-vue-->
  <!--            ref="chart"-->
  <!--            :titleTxt="chartTitle"-->
  <!--            :data="data"-->
  <!--            :toolbar="false"-->
  <!--            :width="width"-->
  <!--            :overlays="overlays"-->
  <!--            :height="height"></trading-vue>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="mx-auto w-full">
    <table role="table" class="transaction-table w-full border-separate border-0">
      <thead class="text-sm text-gray-500 dark:text-gray-300">
      <tr>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Рынок</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Тип</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Статус</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">PnL</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Сигнал</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex">
            <div class="w-full">Время</div>
          </div>
        </th>
        <th class="group bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4">
          <div class="flex items-center">
            <div class="w-full text-center">&nbsp;</div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
      <tr v-if="trades.length === 0"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td colspan="4"
            class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5 text-gray-400">
          Трейдов еще нет
        </td>
      </tr>
      <tr :key="trade.id" v-for="trade in trades"
          class="mb-3 items-center rounded-lg shadow-card last:mb-0 dark:bg-light-dark">
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">
              <p class="font-bold">{{ getMarket(trade).symbol.toUpperCase() }}</p>
              <p class="text-sm text-gray-400">{{ `${getMarket(trade).exchange}:${getMarket(trade).type}` }}</p>
              <p class="text-sm text-gray-400">{{ trade.timeframe }}</p>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">{{ trade.signal.toUpperCase() }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">{{ getStatus(trade) }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">{{ getPnL(trade) }}</div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">
              <p>entry: {{ getEntryPrice(trade) }}</p>
              <p>tp: {{ getTakeProfitPrice(trade) }}</p>
              <p>sl: {{ getStopLossPrice(trade) }}</p>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex">
            <div class="w-full">
              <p>entry: {{ getEntryTime(trade) }}</p>
              <p>exit: {{ getExitTime(trade) }}</p>
            </div>
          </div>
        </td>
        <td class="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
          <div class="flex items-center">
            <div class="w-full text-center">
              <a @click="setCurrentTrade(trade)"
                 class="border rounded-2xl border-dashed border-black p-3 hover:cursor-pointer"
                 href="#">Просмотр</a>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiService from '@/services/api.service'
import {useRoute} from 'vue-router/dist/vue-router'
import {ref} from "vue";
import {DataCube} from "@/components/trading-vue";

export default {
  name: 'BotPage',
  components: {},
  setup() {
    const route = useRoute()
    const bot = ref({})
    const trades = ref([])
    const markets = ref([])

    const width = ref(1000)
    const height = ref(600)
    const data = ref(new DataCube())
    const isCompleted = ref(false)
    const strategy = ref({})
    const chartParent = ref(null)
    const chart = ref(null)
    const currentTrade = ref({})

    const getMarket = (trade) => {
      return markets.value.find(m => m.id === trade.market)
    }

    const getStatus = (trade) => {
      return 'OPEN'
    }

    const getPnL = (trade) => {
      return trade.pnl
    }

    const getEntryPrice = (trade) => {
      return trade.signalEntry
    }

    const getTakeProfitPrice = (trade) => {
      return trade.signalTakeProfit
    }

    const getStopLossPrice = (trade) => {
      return trade.signalStopLoss
    }

    const getEntryTime = (trade) => {
      return ''
    }

    const getExitTime = (trade) => {
      return ''
    }

    const setCurrentTrade = (trade) => {

    }

    const loadBot = () => {
      apiService.getBot(route.params.id)
          .then((response) => {
            bot.value = response.data

            // TODO если пустой маркетс то загружаем все рынки с exchange & type
            for (const marketId of bot.value.markets) {
              apiService.getMarket(marketId)
                  .then((response) => {
                    markets.value.push(response.data)
                  })
            }

            apiService.getTrades({botId: route.params.id})
                .then((response) => {
                  trades.value = response.data
                })
          })
    }
    loadBot()

    // const loadCandles = async () => {
    //   await apiService.loadCandles({
    //     exchange: backtest.value.exchange,
    //     type: backtest.value.type,
    //     base: backtest.value.base,
    //     quote: backtest.value.quote,
    //     timeframe: strategy.value.timeframe,
    //     started_at: backtest.value.startedAt,
    //     finished_at: backtest.value.finishedAt,
    //     from: 0,
    //     size: 10000
    //   }).then(async response => {
    //
    //     data.value.set('chart.data', response.data.sort((d1, d2) => {
    //       return d1[0] - d2[0]
    //     }))
    //   })
    // }

    return {
      bot,
      trades,
      getMarket,
      getStatus,
      getPnL,
      getEntryPrice,
      getTakeProfitPrice,
      getStopLossPrice,
      getEntryTime,
      getExitTime,
      setCurrentTrade
    }
  }
}
</script>

