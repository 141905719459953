<template>
  <div class="mx-auto w-full">
    <h1 class="mb-10 text-2xl font-extrabold dark:text-white">New multitest</h1>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  </div>
  <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-10 mb-10" role="alert">
    <p class="font-bold mb-2">Error:</p>
    <p>{{ errorMessage }}</p>
  </div>
  <form class="w-full">
    <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Backtests params</legend>

      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Strategy
          </label>
          <select v-model="backtest.strategy" @change="onChangeStrategy()"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option :key="strategy.id" :value="strategy.id" :selected="backtest.strategy.id === strategy.id"
                    v-for="strategy in strategies">{{ strategy.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            EXCHANGE
          </label>
          <select v-model="backtest.exchange" @change="onChangeExchange()"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option v-for="exchange in exchanges" :key="exchange" :value="exchange"
                    :selected="backtest.exchange === exchange">{{ exchange }}
            </option>
          </select>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            TYPE
          </label>
          <select v-model="backtest.type" @change="onChangeType()"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option v-for="type in types" :key="type" :value="type" :selected="backtest.type === type">{{ type }}
            </option>
          </select>
        </div>
      </div>

      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Markets
          </label>
          <vue-tags-input
              v-model="backtest.market"
              :tags="backtest.markets"
              :autocomplete-items="getMarketsAutocomplete"
              placeholder="All markets or specific"
              :add-only-from-autocomplete="true"
              @tags-changed="newTags => backtest.markets = newTags"
          />
          <p class="text-gray-400 mt-2">If nothing is selected, then the backtest works for all markets</p>
        </div>
      </div>

      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            TIMEFRAME
          </label>
          <vue-tags-input
              v-model="backtest.timeframe"
              :tags="backtest.timeframes"
              placeholder="Задайте набор таймфреймов"
              :autocomplete-items="getTimeframeAutocomplete"
              :add-only-from-autocomplete="true"
              @tags-changed="newTags => backtest.timeframes = newTags"
          />
        </div>
      </div>

      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            PARAMS
          </label>
        </div>
        <div class="flex flex-row w-full space-x-4 items-center px-3 mb-8" v-for="(item, index) in backtest.params">
          <input
              class="appearance-none block grow w-full bg-gray-200 text-gray-700 rounded py-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.params[index]" type="text" placeholder="BTC">
          <svg @click="deleteParams(index)" xmlns="http://www.w3.org/2000/svg" width="34" height="34"
               fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
          </svg>
          <svg @click="addParams(index)" xmlns="http://www.w3.org/2000/svg" width="34" height="34"
               fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
        </div>
      </div>

      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            STARTED AT
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.startedAt" type="text" placeholder="DD.MM.YYYY HH:mm">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            FINISHED AT
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.finishedAt" type="text" placeholder="DD.MM.YYYY HH:mm">
        </div>
      </div>

      <div class="flex flex-wrap mb-3 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Profit factor threshold
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.profitFactor" type="number" min="0" max="10" step="0.1">
        </div>
      </div>
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Trade params</legend>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Quantity of each trade
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.quantity" type="number">
        </div>
      </div>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Count of open trades
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.maxTrades" type="number">
        </div>
      </div>
      <div class="flex flex-wrap mb-3 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Max of active candles for open trade
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.maxCandles" type="number">
        </div>
      </div>
      <div class="flex flex-wrap mb-3 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Trading fee, %
          </label>
          <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="backtest.fee" type="number" min="0" max="100" step="0.0001">
        </div>
      </div>
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-10 flex flex-wrap -mx-3 mb-6">
      <legend class="text-sm uppercase">Order params</legend>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Entry order type
          </label>
          <select v-model="backtest.entryOrderType"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>limit</option>
            <option>market</option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap mb-6 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Take profit order type
          </label>
          <select v-model="backtest.takeProfitOrderType"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>limit</option>
            <option>market</option>
          </select>
        </div>
      </div>
      <div class="flex flex-wrap mb-3 w-full">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Stop loss order type
          </label>
          <select v-model="backtest.stopLossOrderType"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option>limit</option>
            <option>market</option>
          </select>
        </div>
      </div>
    </fieldset>

    <div class="mx-auto w-full">
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    </div>

    <div class="flex flex-wrap mb-6">
      <div class="w-full md:w-1/2 px-3">
        <LightButton text="Create" @click="start"/>
        <div v-if="loading" class="flex justify-center items-center p-5 w-full bg-white dark:bg-gray-900"
             data-component-loader="">
          <div role="status">
            <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"></path>
              <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"></path>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<script>
import {computed, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import moment from 'moment'
import apiService from '@/services/api.service'
import VueTagsInput from '@sipec/vue3-tags-input'
import LightButton from '@/components/LightButton'

export default {
  name: 'CreateBacktestPage',
  components: {
    LightButton,
    VueTagsInput
  },
  setup() {

    const route = useRoute()
    const router = useRouter()

    const exchanges = ['binance', 'bybit']
    const types = ['futures', 'spot']
    const timeframes = ref(['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d'])
    const backtest = ref({
      exchange: exchanges[0],
      type: types[0],
      profitFactor: 2,
      market: '',
      markets: [],
      timeframe: '',
      timeframes: [{
        text: '15m'
      }],
      params: ['{}'],
      startedAt: '01.01.2023 00:00',
      finishedAt: '31.01.2023 23:59',
      strategy: {},
      quantity: 100,
      maxTrades: 10,
      maxCandles: 5,
      entryOrderType: 'limit',
      takeProfitOrderType: 'limit',
      stopLossOrderType: 'limit',
      fee: 0.1
    })
    const markets = ref([])
    const strategies = ref([])
    const errorMessage = ref(null)
    const loading = ref(false)

    const addParams = (index) => {
      backtest.value.params.push(backtest.value.params[index])
    }

    const deleteParams = (index) => {
      if (backtest.value.params.length > 1) {
        backtest.value.params.splice(index, 1)
      }
    }

    const onChangeStrategy = () => {
      const selectedStrategy = strategies.value.find(s => s.id === backtest.value.strategy)
      if (selectedStrategy) {
        backtest.value.params = [JSON.stringify(selectedStrategy.params)]
      }
    }

    const loadMarkets = () => {
      apiService.getMarkets({
        exchange: backtest.value.exchange,
        type: backtest.value.type
      })
          .then(response => {
            markets.value = response.data
          })
    }
    loadMarkets()

    const onChangeExchange = () => {
      backtest.value.markets = []
      loadMarkets()
    }

    const onChangeType = () => {
      backtest.value.markets = []
      loadMarkets()
    }

    const start = () => {
      loading.value = true
      errorMessage.value = null

      apiService.createBacktest({
        strategy: backtest.value.strategy,
        exchange: backtest.value.exchange,
        type: backtest.value.type,
        markets: backtest.value.markets.map(m => m.id),
        timeframes: backtest.value.timeframes.map(t => t.text),
        params: backtest.value.params.map(p => JSON.parse(p)),
        startedAt: moment(backtest.value.startedAt, 'DD.MM.YYYY HH:mm').utc().unix() * 1000,
        finishedAt: moment(backtest.value.finishedAt, 'DD.MM.YYYY HH:mm').utc().unix() * 1000,
        profitFactor: backtest.value.profitFactor,
        quantity: backtest.value.quantity,
        maxTrades: backtest.value.maxTrades,
        maxCandles: backtest.value.maxCandles,
        entryOrderType: backtest.value.entryOrderType,
        takeProfitOrderType: backtest.value.takeProfitOrderType,
        stopLossOrderType: backtest.value.stopLossOrderType,
        fee: backtest.value.fee
      })
          .then(response => {
            router.push(`/backtests/${response.data.id}/status`)
          })
          .catch(error => {
            loading.value = false
            errorMessage.value = error
          })
    }

    apiService.getStrategies()
        .then(response => {
          strategies.value = response.data

          if (route.query.strategy) {
            const strategyId = parseInt(route.query.strategy)
            const strategy = strategies.value.find(s => s.id === strategyId)
            if (strategy) {
              backtest.value.strategy = strategy.id
              backtest.value.params = [JSON.stringify(strategy.params)]
            }
          }

          if (!backtest.value.strategy) {
            backtest.value.strategy = strategies.value[0].id
            backtest.value.params = [JSON.stringify(strategies.value[0].params)]
          }
        })

    const getMarketsAutocomplete = computed(() => {
      return markets.value
          .filter(market => {
            return market.symbol.startsWith(backtest.value.market)
          })
          .map(market => {
            return {
              id: market.id,
              text: market.symbol
            }
          })
    })

    const getTimeframeAutocomplete = computed(() => {
      return timeframes.value
          .filter(tf => {
            return tf.startsWith(backtest.value.timeframe)
          })
          .map(tf => {
            return {
              text: tf
            }
          })
    })

    return {
      exchanges,
      types,
      timeframes,
      backtest,
      markets,
      strategies,
      errorMessage,
      loading,
      addParams,
      deleteParams,
      getMarketsAutocomplete,
      getTimeframeAutocomplete,
      onChangeStrategy,
      onChangeExchange,
      onChangeType,
      start
    }
  }
}
</script>

