export default (short) => {
    if (short) {
        return {
            isEntry: short.isEntry,
            entry: short.entry,
            takeProfit: short.takeProfit,
            stopLoss: short.stopLoss,
            maxTrades: short.maxTrades,
            maxCandles: short.maxCandles
        }
    } else {
        return {
            isEntry: '',
            entry: '',
            takeProfit: '',
            stopLoss: '',
            maxTrades: 0,
            maxCandles: 0
        }
    }
}