<template>
  <h1 class="mb-10 text-2xl font-extrabold dark:text-white">Update script</h1>
  <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
  <div v-if="errorMessage" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-10 mb-10" role="alert">
    <p class="font-bold mb-2">Error:</p>
    <p>{{ errorMessage }}</p>
  </div>
  <form class="w-full">
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Name
        </label>
        <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            v-model="script.name" type="text" placeholder="">
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Arguments
        </label>
        <vue3-tags-input :tags="script.args" @on-tags-changed="handleChangeTag"
                         placeholder="Add script arguments"/>
      </div>
    </div>
    <div class="flex flex-wrap -mx-3">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Script code
        </label>
      </div>
    </div>
    <div class="flex border border-gray-300">
      <CodeEditor v-model="script.code" :language_selector="false" :display_language="false" :height="'400px'"
                  :width="'100%'" :copy_code="false" theme="light"></CodeEditor>
    </div>
    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
    <div class="flex flex-wrap -mx-3 mb-6 mt-10">
      <div class="w-full md:w-1/2 px-3">
        <LightButton text="Save" @click="createScript"/>
      </div>
    </div>
  </form>
</template>

<script>
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import apiService from '@/services/api.service'
import CodeEditor from 'simple-code-editor'
import Vue3TagsInput from 'vue3-tags-input'
import LightButton from "@/components/LightButton";

export default {
  name: 'CreateScriptPage',
  components: {
    LightButton,
    CodeEditor,
    Vue3TagsInput
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const loading = ref(false)
    const errorMessage = ref(null)
    const script = ref({
      name: '',
      args: [],
      code: `return close + open`
    })

    const handleChangeTag = (args) => {
      script.value.args = args
    }

    const createScript = () => {
      loading.value = true
      errorMessage.value = null

      apiService.updateScript(route.params.id, script.value)
          .then(response => {
            router.push(`/scripts`)
          })
          .catch(error => {
            loading.value = false
            errorMessage.value = error
          })
    }

    apiService.getScript(route.params.id)
        .then(response => {
          script.value = response.data
        })

    return {
      createScript,
      loading,
      errorMessage,
      script,
      handleChangeTag
    }
  }
}
</script>
